import firebase from 'firebase/compat/app';
import axios, {AxiosRequestConfig, ResponseType} from 'axios';
import {businessQuestions, productSkuFeaturesRoles, reportTypes} from '@/config/globalVars';
import {addMinutes, format} from 'date-fns';
import {getAllAlerts, getAllReports, getAllStories, getProductBySku, getRunConfigOptionsBySku} from '@/services/product.service';
import {disableApiAuth} from '@/config';
import {ReportDatatype, ReportRunConfig, LegacyCharacteristic} from '@/types/ReportRun';
import {AlertRunConfig, UserAlertSummary} from '@/types/AlertRun';
import {emailDomainFoundInDomainList, MrktEntitlementType, reportRunTimePeriodToString} from '@/utils';
import {StoryRun} from '@/types/StoriesTypes';
import {SimulatorDataset, SimulatorDatasetValue, SimulatorInsightFilters} from '@/types/SimulatorTypes';
import {QuoteAcceptReqType, QuoteDetail, QuoteIgnoreReqType, QuoteRequestType, QuoteResponseType} from '@/types/QuoteTypes';
import {MarketMap} from '@/types/MarketMaps';
import {MIME_TYPES} from '@/constants/marketMaps.constants';
import {useUser} from "@/contexts/UserContext";
import {useEffect} from "react";
import {DodExcelTemplate, DodRunConfig, DodSchedule} from "@/types/DodRun";
import {normalizeTimezone, toMaxDataDates} from "@/utils/toMaxDataDates";
import {
    BaseViewPromotionResultDTO,
    CompanyCreditUsage,
    Criteria,
    CustomCharPayload,
    DataRefreshNotificationFrequency,
    DefaultRunConfig,
    DodPreset, DodPresetMap,
    FreeUserDefaultRunConfig,
    FullAccount,
    GetChildMarketNodesParams,
    GetMarketNodesParams,
    MarketMapResponse,
    PpgGroupNameFilters,
    PpgGroupNames,
    PpgInfo,
    ProductValueFilterParams,
    ReportletDetails,
    ReportRun,
    ReportRunSummary,
    ReportRunValidationResult,
    RootMarketNodes,
    SearchMarketsParams,
    TenantType,
    TopCategory,
    UpcDescriptionPair,
    UPCDetails,
    UpcGroupNames,
    UPCSearchError,
    UpdateReportletReqBody,
    UserRunConfig,
    UserUpdate,
    PlatformNotificationEmails,
    AlertHistoryResponse,
    UsageCounts,
    PersonaPayload
} from '@/types/ApiTypes';
import {DomainMismatchReasonCode, ExternalUser, Team, TeamMember, UserInvite} from "@/types/InvitationTypes";
import { normalizeDodConfig } from '@/api';
import { OpportunityRunConfig } from '@/types/ScoreCard';
import { ExtractRun } from '@/types/DataOnDemand';
import moment from 'moment';

const http = axios.create({
    // @ts-ignore - todo - figure out why import.meta has type error
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

let dpAbortController = new AbortController();
let extractAbortController = new AbortController();
if (disableApiAuth !== true) {
    http.interceptors.request.use(async (config) => {
        if (firebase.auth().currentUser) {
            // @ts-ignore
            config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
        } else {
            console.warn('no auth user available')
        }
        return config;
    });
}

export const useTenantApi = () => {
    const { tenantId, setCategories, nsCompany, refreshUser } = useUser();

    useEffect(() => {
        setTenantApiHeader(tenantId);
    }, [tenantId]);

    /*
    Watches auth token state changes to keep the request header in sync
    */
    function setTenantApiHeader(id: string | undefined): void {
        http.defaults.headers['x-tenant-id'] = id;
    }

    // http.interceptors.response.use((response) => response, async (error) => {
    //     if (error?.response?.data?.code === 'auth_user_not_found') {
    //         await openErrorModal({
    //             title: `Account Not Found`,
    //             content: (
    //                 <>
    //                     <p>Your session seems to have expired</p>
    //                     <p>Please login again.</p>
    //                 </>
    //             ),
    //         });
    //         await firebase.auth().signOut();
    //     }
    //     return error
    // })

    /**
     * This is meant to be used in {@link setOboUserInRequestHeaders}
     * @param {string} email the email id of user to be logged in as
     * @param {string} role the role name of CSR Obo
     */
    function setApiOboUser(email, role) {
        http.defaults.headers['X-byzzer-obo'] = email;
        http.defaults.headers['X-byzzer-obo-role'] = role;
    }

    async function markTipViewed(tipKey: string): Promise<string[]> {
        return httpPut(`/my/viewed_tips/${tipKey}`);
    }

    async function estimateExtractDataPoints(config: DodRunConfig): Promise<number> {

        try {
            extractAbortController.abort();
            extractAbortController = new AbortController();
            const {estimate} = await httpPost('/extracts.estimate_data_points', normalizeDodConfig(config), {
                signal: extractAbortController.signal,
            });
            return estimate;
        } catch (err) {
            throw err;
        }
        
    }

    async function createExtract(name: string, isFutureTemplate: boolean, config: DodRunConfig): Promise<DodRunConfig> {

        return httpPost('/extracts', {
            ...normalizeDodConfig(config),
            name,
            isFutureTemplate
        });
    }

    async function createExtractSchedule(name: string, isFutureTemplate: boolean, config: DodRunConfig, schedule: DodSchedule): Promise<DodRunConfig> {

        return httpPost('/extract_schedules', {
            ...normalizeDodConfig(config),
            name,
            isFutureTemplate,
            schedule,
        });
    }

    async function getAvailableTenants(): Promise<TenantType[]> {

        return httpGet('/my/tenants');
    }

    async function getChrCodeDisplayMap() {
        let displayMap;
        // const { getReferenceData } = useTenantApi();
        // this will only get called once hopefully at when the application loads and the cached result will be used every place else.
        if (!displayMap) {
            displayMap = getReferenceData('subscription_characteristic_codes').then((entries) =>
                entries.reduce((map, {display, value}) => {
                    map[value] = display;
                    return map;
                })
            );
        }

        return displayMap;
    }

    async function updateCategories(categories: string[]): Promise<string[]> {

        const newCategories = await httpPut('/my/company/category_names', categories);
        // update the user context
        setCategories(newCategories);

        return newCategories;
    }

    async function updateOmniCategories(categories: string[]): Promise<string[]> {
        return httpPost('/my/company/omni_category_names', categories)
    }

    async function getBrandScoreOpportunities(obj: OpportunityRunConfig, type: string): Promise<string[]> {
        const opportunities = await httpPost(`/actions/opportunities/${type}`, obj);

        return opportunities?.opportunities as string[];
    }

    /**
     * This is meant to be used in {@link clearOboUserFromRequestHeaders}
     */
    function clearApiOboUser() {
        delete http.defaults.headers['X-byzzer-obo'];
        delete http.defaults.headers['X-byzzer-obo-role'];
    }

    async function lookup(paramsObj?: any) {
        const res = http.get(
            `/product/lookup`,
            // @ts-ignore
            paramsObj
                ? {
                    params: paramsObj,
                }
                : null
        );
        return await res;
    }

    async function getCompanyReportRuns(subscribedSkus: string[]): Promise<ReportRunSummary[]> {
        const reportRuns = await httpGet('/my/report_runs');

        // console.log(`api.service - getCompanyReportRuns fired - 'reportRuns' ===>>`, reportRuns);
        const handleReportRunsMap = ({timePeriod, ...reportRun}) => {
            const [start, end] = timePeriod.endDate?.split(' to ') ?? [];
            const weeks = +(timePeriod.weeks || timePeriod.timePeriod?.match(/\d+/)?.[0]);
            const months = timePeriod.months;
            const timePeriodToFormat = {
                start,
                end, //note:the property end never exist for omni reports,this is always undefined for omni
                period: timePeriod.timePeriod,
                weeks: isNaN(weeks) ? undefined : weeks,
                months: isNaN(months) ? undefined : months,
                omniTimePeriod: timePeriod?.omniTimePeriod,
            };
            // console.log(`api.service - getCompanyReportRuns-handleReportRunsMap fired - 'timePeriodToFormat' ===>>`, timePeriodToFormat);
            return {
                ...reportRun,
                // timePeriod,
                timePeriod: reportRunTimePeriodToString(timePeriodToFormat),
                report: getProductBySku(reportRun.sku),
                //runDtm: formatInTimeZone(new Date(reportRun.runDtm), 'America/New_York', 'yyyy-MM-dd'), // formatting in the api response itself removes the timestamp and thus disturbs whole sorting process in report history grid, hence commenting out till ag grid date sorting is enabled
                runnable: Boolean(subscribedSkus?.includes(reportRun.sku)),
            };
        };

        const reportRunsMapped = reportRuns.map(handleReportRunsMap);

        // console.log(`api.service - getCompanyReportRuns fired - 'reportRunsMapped' ===>>`, reportRunsMapped);
        return reportRunsMapped;
        // todo: populate the runnable flag here so doesn't have to get calculated the consuming component
    }

    //TODO: will be implemented once Yong completes his backend changes
    async function saveFavorites(id: number, favorite: boolean) {
        await httpPost('/products/productAction/favorite', {id, favorite});
    }

    async function saveNotes(id: number, notes: string) {
        await httpPost('/products/productAction/notes', {id, notes});

    }

    // todo: have this return the ReportRun object
    async function createSubscriptionReportRun(sku: string, runConfig: ReportRunConfig): Promise<string> {
        const {productActionId} = await httpPost('/report_runs', {
            runType: 'subscription',
            sku,
            runConfig,
        });
        return productActionId;
    }

    // resource.action -> RPC (remote procedure call, always a POST, verb in name); vs RESTful, never a verb in name
    async function getSimulatorDatasetValues(runConfig: SimulatorInsightFilters, sku: string, datasetConfig?: SimulatorDatasetValue[]): Promise<BaseViewPromotionResultDTO> {
        const dataset: BaseViewPromotionResultDTO = await httpPost('/datasets.run', {runConfig, sku});

        if (Object.entries(dataset).every(([k, v]) => v === null || v === 0)) {
            throw {
                code: 'results_not_found'
            }
        } else if (Object.entries(dataset).some(([datasetKey, datasetValue]) => (
            datasetConfig?.find(({code}) => code === datasetKey)?.required && (datasetValue === null || datasetValue === 0)
        ))) {
            throw {
                code: 'partial_results'
            }
        }
        return dataset;
    }

    async function getReportRunLink(id: number): Promise<string> {
        const link = await httpGet(`/report_runs/${id}/link`);
        return link;
    }

    async function validateRunConfig(
        runType: RunType,
        sku: string,
        runConfig: ReportRunConfig
    ): Promise<ReportRunValidationResult> {
        return httpPost('/report_runs.validate', {
            runType,
            sku,
            runConfig,
        });
    }

    // todo: have this return the ReportRun object
    async function createAdHicReportRun(creditId: string, runConfig: ReportRunConfig): Promise<string> {
        const {productActionId} = await httpPost('/report_runs', {
            type: 'adhoc',
            creditId,
            runConfig,
        });
        return productActionId;
    }

    async function getSubcategoriesForCategories(categories: string[]): Promise<string[]> {
        const subcategories = await httpPost('/product/subcategoriesForCategories', {
            categories,
        });
        return subcategories;
    }

    async function getReportRunById(id: number): Promise<ReportRun> {
        const reportRun = await httpGet(`/my/report_runs/${id}`);

        return {
            ...reportRun,
            report: getProductBySku(reportRun.sku),
        };
    }

    async function getCreditInfoById(id: number): Promise<any> {
        const creditInfo = await httpGet(`/my/credits/${id}`);
        return creditInfo;
    }

    // Todo: Move this back to product.service.ts, needs refactor to become hook to use 'useTenantApi', otherwise it's an invalid hook call
    async function getRunConfigOptionsByCreditId(creditId: number): Promise<any> { // todo: define shape of response
        const creditInfoById = await getCreditInfoById(creditId);

        const runConfigOptionsForSku = getRunConfigOptionsBySku(creditInfoById.sku);

        // @ts-ignore
        const productOptions = runConfigOptionsForSku.find(({type}) => type === 'product') as ProductRunConfigOptions;
        // @ts-ignore
        const nonProductOptions = runConfigOptionsForSku.filter(({type}) => type !== 'product') as Omit<AlertRunConfigOptions | RunConfigOptions, "ProductRunConfigOptions">[];

        const totalCountOfAdhocSelectableCategories = 1 + creditInfoById?.childCredits?.filter((credit) => credit?.type === 'product_addon.category')?.length;

        const maxCategories = productOptions?.maxCategories ? Math.min(
            productOptions?.maxCategories,
            totalCountOfAdhocSelectableCategories
        ) : totalCountOfAdhocSelectableCategories;

        const runConfigOptionsForSkuWithAddons = [
            {
                ...productOptions,
                maxCategories
            },
            ...nonProductOptions
        ]

        return runConfigOptionsForSkuWithAddons;
    }

    function toReportRun(rawReportConfig: any): ReportRun {
        let newReportRun: any = JSON.parse(JSON.stringify(rawReportConfig));

        const timePeriod = newReportRun?.configuration?.timePeriod;

        if (timePeriod?.type === 'week_ending' && typeof timePeriod?.period?.endDate === 'string') {
            newReportRun.configuration.timePeriod.period.endDate = new Date(timePeriod?.period?.endDate);
        }

        return newReportRun;
    }

    async function getReportRunConfigById(id: number): Promise<ReportRun> {
        const rawReportConfig = await httpGet(`/my/report_runs_config/${id}`);

        const convertedRunConfig = toReportRun(rawReportConfig);

        return convertedRunConfig;
    }

    async function getMyReportlets() {
        // todo: implement on server.  this is the proper restful api definition
        return httpGet('/my/reportlets');
    }

    async function getReportById(id: number) {
        // todo: implement on server.  this is the proper restful api definition
        return httpGet(`/my/reportlets/${id}`);
    }

    async function getOmniDates() {
        try {
            const res = await httpGet(`/report/omniEndDates`);
            let _formattedArr: any = [];
            res?.map(
                (item) => _formattedArr.push(normalizeTimezone(item as any)) //TODO:to check how to avoid/replace any
            );
            return _formattedArr || [];
        } catch (err) {
            return err;
        }
    }

    async function homePageTiles() {
        return (await http.get(`/reportlet/byUserId`)).data;
    }

    async function homePageTileById(tileId) {
        return (await http.get(`/reportlet/byId/${tileId}`)).data;
    }

    async function productTourDataFetch() {
        return httpGet('/external/dashboard/product_tour');
    }

    async function getMyNotifications() {
        return httpGet('/message/message_feed');
    }

    async function updateNotification(notification) {
        return httpPost(`/message/update_message`, notification);
    }

    async function search(searchKeyword) {
        const res = http.get(`/product/search/${searchKeyword}`);
        return await res;
    }

    async function findRetailProducts(searchBy, text) {
        return httpGet(`/product/search/${searchBy}/${encodeURIComponent(text)}`);
    }

    async function getReferenceData(type: string, params?: object) {
        return httpGet(`/reference_data/${type}`, {
            params,
        });
    }

    async function getHierarchyData(level, title) {
        return httpGet('/product/lookup', {
            params: {
                hierarchy: level,
                value: title,
            },
        });
    }

    async function getDemographicValues(code: string): Promise<{ values: string[] }> {
        const resp = await httpGet(`/product/demographicValues/${code}`);
        if (code === 'ZIP_CODE') {
            return {
                values: resp.values.map((val: string) => {
                    return ('00000' + val).slice(-5);
                }),
            };
        }
        return resp;
    }

    async function getMarketKeyFromMarkets(markets) {
        try {
            const res = await http.post(`/market/getMarketKeyFromMarkets`, markets);
            return res.data || [];
        } catch (err) {
            return err;
        }
    }

    async function findCompanyByDomain(domain) {
        const {data} = await http.get('/reference_data/company_name', {
            params: {
                domain,
            },
        });
        return data;
    }

    async function getReportLink(options) {
        return httpPost(`/report`, options);
    }

    async function deleteProductUsingActionId(actionId) {
        try {
            const resp = httpDelete(`/products/actions/${actionId}`);
            return await resp;
        } catch (err) {
            return err;
        }
    }

    async function getReportLinkByName(name) {
        try {
            const res = await http.post(`/report/byName`, {name});
            return {status: 'success', url: res.data};
        } catch (error) {
            return {status: 'Report UI api error', message: error};
        }
    }

    async function getCustomCharReportLinkById(id) {
        return httpGet(`/report/customCharDefinitions/${id}`);
    }

    async function getPPGReportLinkById(id) {
        return httpGet(`/report/ppgDefinitions/${id}`);
    }

    async function getDrillDownReportLink(requestBody) {
        return httpPost(`/report/drilldown`, requestBody);
    }

    async function getScorecardReport({
                                          scorecardId,
                                          focusBrands,
                                          focusUPC = '',
                                          focusCategories = [],
                                          timePeriod,
                                          markets = [],
                                          companyCategories = [],
                                          characteristics = [],
                                          customSegmentIds = [],
                                          ppgId,
                                          sortingField,
                                          sortingFieldValue,
                                      }) {
        return httpPost(`/report/uncharged`, {
            reportConfig: {
                focusProductSelections: {
                    focusBrand: focusBrands,
                    showFocusBrand: true,
                    focusUPC,
                    competitiveProductSet: {
                        categories: [].concat(focusCategories),
                        subcategories: [],
                    },
                    characteristicDimension: '',
                    characteristicFilters: characteristics,
                    customSegmentIds,
                },
                focusMarketSelections: {
                    focusMarket: {
                        byMarketName: {
                            marketGroup: 'rms',
                            markets,
                        },
                        byMarketType: null,
                    },
                },
                timePeriodSelections: timePeriod ?? {
                    endDate: '',
                    weeks: '',
                    timePeriod: 'Latest 52 Weeks', // Need to pass Latest 52 Weeks by default as per #2287
                    selectionType: 'pre-defined',
                },
                comparisonType: '',
                ppgId,
                productSet: companyCategories.map((name) => ({
                    hierarchy: 'CATEGORY',
                    name,
                })),
                sortingField:sortingField,
                sortingFieldValue:sortingFieldValue,
            },
            reportSku: scorecardId,
        });
    }

    // fetch latest custom start date for monthly scheduling
    async function getCustomStartDate() {
        return await httpGet(`/data_on_demand/getStartDate`);
    }

    async function getMaxDataDates(): Promise<MaxDataDates> {
        const [cps, rms, omni] = await Promise.all([getMaxDataDate('cps'), getMaxDataDate('rms'), getMaxDataDate('omni')]);

        return {
            cps,
            rms,
            omni,
        };
    }

    async function getLatestMaxDataDate(reportDataType: ReportDatatype) {
        try {
            const res = await http.get(`/report/latestMaxDataDate`, {
                params: {
                    reportDataType,
                },
            });
            return {status: 'success', maxDataDate: res.data};
        } catch (err) {
            return {status: 'error', message: err};
        }
    }

    async function getMaxDataDate(type: ReportDatatype): Promise<Date | undefined> {
        const rawDate = await httpGet(`/report/latestMaxDataDate`, {
            params: {
                reportDataType: type,
            },
        });

        if (!rawDate) return;
        // rawData is in GMT which is wrong, so we'll discard the time and timezone

        return normalizeTimezone(rawDate);
    }

    async function getCreditUsage(creditId) {
        // todo: replace with the actual credit end point once it's implemented
        return httpGet(`/products/actionCount`, {
            params: {
                contractItemInstanceId: creditId,
            },
        });
    }

    async function getSubscriptionActionCount() {
        try {
            const res = await http.get(`/products/subscriptionActionCount`);
            return {status: 'success', ...res.data};
        } catch (error) {
            return {status: 'error', message: JSON.stringify(error)};
        }
    }

    async function getMySubscription() {
        const subscription = await httpGet('/my/subscription');

        return subscription;
    }

    async function getMyDownloadableProducts() {
        return await httpGet('/my/downloadable_products');
    }

    async function getMyLegacySubscription() {
        const subscription = await httpGet('/my/subscription', {
            headers: {
                Accept: 'application/vnd.subscription-legacy+json',
            },
        });

        infinitizeUsage(subscription.usage);

        return subscription;
    }

    async function getMySubscriptionUsage(): Promise<CompanyCreditUsage> {
        const usage = await httpGet('/my/subscription/usage');
        infinitizeUsage(usage);
        return usage;
    }

    async function getMyCompanySubscription() {
        const subscription = await httpGet('/my/company/subscription');

        infinitizeUsage(subscription.usage);
        return subscription;
    }

    async function getMyCompanySubscriptionUsage(): Promise<UsageCounts> {
        const usage = await httpGet('/my/company/subscription/usage');
        infinitizeUsage(usage);
        return usage;
    }

    async function getMyProducts({include, exclude}) {
        return httpGet('/my/products', {
            params: {
                include,
                exclude,
            },
        });
    }

    async function isScorecardEnabled() {
        const enabled = await httpGet('/hack.has_scorecard_access');
        return enabled === true;
    }

    async function getDatasetById(datasetId: string | number): Promise<SimulatorDataset> {
        return httpGet(`/datasets/${datasetId}`);
    }

    async function getMyProductsByType(type) {
        const products = await httpGet('/my/products', {
            params: {
                include: [type, 'subscription'],
            },
        });

        const subscription = products.find(entityHasType('subscription'));

        return {
            status: 'success',
            reports: products.filter(entityHasType('report')).map(({usage, ...report}) => ({
                ...report,
                disableReport: usage.used >= usage.limit,
            })),
            alerts: {
                returnAlerts: subscription?.products.filter(entityHasType('alert')) ?? [],
                productActionQuantity: subscription?.usage.basicAlerts + subscription?.usage.premiumAlerts,
            },
            packageType: subscription?.title,
            sku: subscription?.sku,
        };
    }

    function entityHasType(requiredType) {
        return ({type}) => type === requiredType;
    }

    /**
     * Get User Favirite filtered by type
     * @param {string} type favorite type string
     * @return {object} error/response
     */
    async function getUserFavorite(type: string, signal: AbortSignal | undefined): Promise<string[]> {
        return httpGet(`/me/favorites/${type}`,
          {
              signal: signal,
          });
    }

    /**
     * Create User Favorite filtered by type
     * Payload is merged based on key over existing data
     * no need to send history can only send property to be updated
     * @param {string} type favorite type string
     * @param {object} payload Payload data to be updated
     * @return {object} error/response
     */
    async function createUserFavorite(type, value) {
        return httpPut(`/me/favorites/${type}/${value}`);
    }

    /**
     * delete User Favirite filtered by type
     * @param {string} type favorite type string
     * @return {object} error/response
     */
    async function deleteUserFavorite(type, value) {
        return httpDelete(`/me/favorites/${type}/${value}`);
    }

    async function checkUpcsInCategories({upcs, categories}) {
        return httpPost(`/actions/check_upcs_against_categories`, {
            upcs,
            categories,
        });
    }

    async function getMyPPGs() {
        return httpGet('/my/ppgs');
    }

    async function getPPGById(ppgId: number): Promise<PpgInfo | undefined> {
        return httpGet(`/my/ppgs/${ppgId}`);
    }

    async function getPpgGroupNames<T extends (PpgGroupNames | UpcGroupNames) = (PpgGroupNames | UpcGroupNames)>(filters: PpgGroupNameFilters): Promise<T | undefined> {
        const ppgGroupNames: Promise<T | undefined> = httpPost(`/my/ppgs/groupNames`, {filters});
        return ppgGroupNames;
    }

    async function createPPG(data) {
        return httpPost(`/my/ppgs`, data);
    }

    async function updatePPG(id, data) {
        return httpPatch(`/my/ppgs/${id}`, data);
    }

    async function deletePPG(id) {
        return httpDelete(`/my/ppgs/${id}`);
    }

    async function getCustomCharacteristics(): Promise<CustomCharacteristic[]> {
        const characteristics = await httpGet('/my/customChars');
        return characteristics.map(c => ({
            ...c,
            createdDtm: new Date(c.createdDtm),
            updatedDtm: new Date(c.updatedDtm)
        }));
    }

    async function getCustomChars() {
        return httpGet('/my/customChars');
    }

    async function getCustomChar(id:number, withNASegment?:boolean) {
        return httpGet(`/my/customChars/${id}${withNASegment? '/na' : '' }`);
    }

    async function getCustomCharWithNASegment(id): Promise<string[]> {

        const {type, groups, segments} = await httpGet(`/my/customChars/${id}/na`);

        if (type === 'UPC') {
            return groups?.map(group => group.name) ?? [];
        }
        return segments?.map(segment => segment.name) ?? [];
    }

    async function createCustomChar(data: CustomCharPayload): Promise<{id: number}> {
        return httpPost(`/my/customChars`, data);
    }

    async function renameCustomChar(id: number, newName: string) {
        return httpPatch(`/my/custom_char/rename/${id}`, {newName});
    }

    async function updateCustomChar(id: number, data: CustomCharPayload) {
        return httpPut(`/my/customChars/${id}`, data);
    }

    async function deleteCustomChar(id: number) {
        return httpDelete(`/my/customChars/${id}`);
    }

    async function getReportEmbedFromActionHistory(actionHistoryId) {
        return httpGet(`/report/embedLinkFromAction`, {
            params: {
                actionId: actionHistoryId,
            },
        });
    }

    async function getActionHistory(actionId) {
        return httpGet(`/products/actions/${actionId}`);
    }

    async function getUserAlertHistory(): Promise<AlertHistoryResponse> {
        try {
            const alertsHistory = await httpGet(`/products/getAlertHistory`, {
                params: {
                    limit: 50
                },
            });

            return {status: 'success', alerts: alertsHistory};
        } catch (error) {
            return {status: 'error', message: JSON.stringify(error)};
        }
    }

    async function getCompanyReportHistory() {
        try {
            const res = await http.get(`/products/actionsByCompany`, {
                params: {
                    productType: 'report',
                    actionType: 'run',
                },
            });
            return {status: 'success', reports: [...res.data]};
        } catch (error) {
            return {status: 'error', message: JSON.stringify(error)};
        }
    }

    async function marketLookup(paramsObj) {
        const res = await http.post(`/market/lookup`, paramsObj);
        return res;
    }

    async function dodMarketLookup(paramsObj, normalCategories, summedCategories, summedCategoryObjects, sumSelection) {
        if (summedCategoryObjects?.length > 0 && normalCategories?.length > 0) {
            let forceSumSelection = false;
            const normalCatResult = await http.post(`/market/lookup`, {
                ...paramsObj,
                requiresAllCategoriesApproved: false,
                categories: normalCategories,
            });

            let summedCatResult: any = [];
            const sumSelectionResults = await Promise.all(summedCategoryObjects.map(async (obj) => {
                return await http.post(`/market/lookup`, {
                    ...paramsObj,
                    requiresAllCategoriesApproved: false,
                    categories: obj.selections || [],
                });
            }));

            if (sumSelectionResults[0]?.data?.markets?.length) {

                let resultantMarkets: any = [];
                let combinedResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data?.markets?.forEach((market) => {
                        let combinedMrktEntitlement = combinedResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = combinedResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            combinedResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            combinedResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of combinedResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            resultantMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            resultantMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL],
                        });
                        forceSumSelection = true;
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }
                summedCatResult = {
                    "data": {
                        "markets": resultantMarkets
                    }
                };
            } else {

                let totalUsMarkets: any = [];
                let totalUsResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data['Total US']?.forEach((market) => {
                        let combinedMrktEntitlement = totalUsResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = totalUsResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            totalUsResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            totalUsResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of totalUsResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            totalUsMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            totalUsMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }

                let byGeographyMarkets: any = [];
                let byGeographyMarketsResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data['By Geography']?.forEach((market) => {
                        let combinedMrktEntitlement = byGeographyMarketsResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = byGeographyMarketsResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            byGeographyMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            byGeographyMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of byGeographyMarketsResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            byGeographyMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            byGeographyMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }

                let channelFMCGMarkets: any = [];
                let channelFMCGMarketsResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data['Select By Channel']['By Channel FMCG']?.forEach((market) => {
                        let combinedMrktEntitlement = channelFMCGMarketsResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = channelFMCGMarketsResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            channelFMCGMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            channelFMCGMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of channelFMCGMarketsResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            channelFMCGMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            channelFMCGMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }

                let channelSpecialityMarkets: any = [];
                let channelSpecialityMarketsResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data['Select By Channel']['By Channel Speciality']?.forEach((market) => {
                        let combinedMrktEntitlement = channelSpecialityMarketsResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = channelSpecialityMarketsResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            channelSpecialityMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            channelSpecialityMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of channelSpecialityMarketsResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            channelSpecialityMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            channelSpecialityMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }

                summedCatResult = {
                    "data": {
                        "Total US": totalUsMarkets,
                        "Select By Channel": {
                            "By Channel FMCG": channelFMCGMarkets,
                            "By Channel Speciality": channelSpecialityMarkets
                        },
                        "By Geography": byGeographyMarkets
                    }
                };
            }

            return mergeResult(normalCatResult, summedCatResult, forceSumSelection ? forceSumSelection : sumSelection);
        }
        if (summedCategoryObjects?.length > 0 && normalCategories?.length === 0) {
            const sumSelectionResults = await Promise.all(summedCategoryObjects.map(async (obj) => {
                return await http.post(`/market/lookup`, {
                    ...paramsObj,
                    requiresAllCategoriesApproved: false,
                    categories: obj.selections || [],
                });
            }));

            if (sumSelectionResults[0]?.data?.markets?.length) {

                let resultantMarkets: any = [];
                let combinedResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data?.markets?.forEach((market) => {
                        let combinedMrktEntitlement = combinedResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = combinedResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            combinedResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            combinedResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of combinedResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            resultantMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            resultantMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }
                return {
                    "data": {
                        "markets": resultantMarkets // array of markets
                    }
                };
            } else {

                let totalUsMarkets: any = [];
                let totalUsResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data['Total US']?.forEach((market) => {
                        let combinedMrktEntitlement = totalUsResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = totalUsResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            totalUsResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            totalUsResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of totalUsResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            totalUsMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            totalUsMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }

                let byGeographyMarkets: any = [];
                let byGeographyMarketsResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data['By Geography']?.forEach((market) => {
                        let combinedMrktEntitlement = byGeographyMarketsResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = byGeographyMarketsResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            byGeographyMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            byGeographyMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of byGeographyMarketsResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            byGeographyMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            byGeographyMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }

                let channelFMCGMarkets: any = [];
                let channelFMCGMarketsResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data['Select By Channel']['By Channel FMCG']?.forEach((market) => {
                        let combinedMrktEntitlement = channelFMCGMarketsResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = channelFMCGMarketsResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            channelFMCGMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            channelFMCGMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of channelFMCGMarketsResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            channelFMCGMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            channelFMCGMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }

                let channelSpecialityMarkets: any = [];
                let channelSpecialityMarketsResultMap = new Map();

                sumSelectionResults.forEach((response) => {
                    response?.data['Select By Channel']['By Channel Speciality']?.forEach((market) => {
                        let combinedMrktEntitlement = channelSpecialityMarketsResultMap.get(market?.name)?.combinedMrktEntitlement;
                        let combinedIsReleasable = channelSpecialityMarketsResultMap.get(market?.name)?.combinedIsReleasable;

                        if (combinedMrktEntitlement?.length) {
                            combinedMrktEntitlement.push(market?.mrkt_entitlements);
                            combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                            combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                            channelSpecialityMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                                combinedIsReleasable: combinedIsReleasable ?? true,
                                others: {...market}
                            });
                        } else {
                            channelSpecialityMarketsResultMap.set(market?.name, {
                                combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                                combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                                others: {...market}
                            });
                        }
                    });
                });

                for (let entry of channelSpecialityMarketsResultMap.values()) {
                    if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            channelSpecialityMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: false,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                            });
                        } else {
                            channelSpecialityMarkets.push({
                                ...entry.others,
                                is_releasable_mrkt: true,
                                mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                            });
                        }
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                        });
                    } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                        });
                    } else {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: entry.combinedIsReleasable,
                            mrkt_entitlements: entry.combinedMrktEntitlement
                        });
                    }

                }

                return {
                    "data": {
                        "Total US": totalUsMarkets,
                        "Select By Channel": {
                            "By Channel FMCG": channelFMCGMarkets,
                            "By Channel Speciality": channelSpecialityMarkets
                        },
                        "By Geography": byGeographyMarkets
                    }
                };
            }
        }
        if (summedCategoryObjects?.length === 0 && normalCategories?.length > 0) {
            let res;
            if (sumSelection) {
                res = await http.post(`/market/lookup`, {
                    ...paramsObj,
                    requiresAllCategoriesApproved: true,
                    categories: normalCategories,
                });
            } else {
                res = await http.post(`/market/lookup`, {
                    ...paramsObj,
                    requiresAllCategoriesApproved: false,
                    categories: normalCategories,
                });
            }

            if (res.data.markets) {
                const newResult = res.data.markets.map((market) => {
                    if (Array.isArray(market?.mrkt_entitlements)) {
                        return {
                            ...market,
                            'mrkt_entitlements': [...market?.mrkt_entitlements]
                        };
                    } else {
                        return {
                            ...market
                        };
                    }

                });
                return {
                    ...res,
                    "data": {
                        "markets": newResult
                    }
                };
            } else {
                const totalUsMarkets = res.data['Total US'].map((market) => {
                    return {
                        ...market,
                        'mrkt_entitlements': Array.isArray(market?.mrkt_entitlements) ? [...market?.mrkt_entitlements] : []
                    };
                });
                const byGeographyMarkets = res.data['By Geography'].map((market) => {
                    return {
                        ...market,
                        'mrkt_entitlements': Array.isArray(market?.mrkt_entitlements) ? [...market?.mrkt_entitlements] : []
                    };
                });
                const channelFMCGMarkets = res.data['Select By Channel']['By Channel FMCG'].map((market) => {
                    return {
                        ...market,
                        'mrkt_entitlements': Array.isArray(market?.mrkt_entitlements) ? [...market?.mrkt_entitlements] : []
                    };
                });
                const channelSpecialityMarkets = res.data['Select By Channel']['By Channel Speciality'].map((market) => {
                    return {
                        ...market,
                        'mrkt_entitlements': Array.isArray(market?.mrkt_entitlements) ? [...market?.mrkt_entitlements] : []
                    };
                });

                const newResult = {
                    ...res,
                    "data": {
                        "Total US": totalUsMarkets,
                        "Select By Channel": {
                            "By Channel FMCG": channelFMCGMarkets,
                            "By Channel Speciality": channelSpecialityMarkets
                        },
                        "By Geography": byGeographyMarkets
                    }
                };
                return newResult;
            }

        }
    }

    const mergeResult = (normalCatResults, summedCatResults, sumSelection) => {
        let combinedResults = [normalCatResults, summedCatResults];

        if (combinedResults[0]?.data?.markets?.length) {

            let resultantMarkets: any = [];
            let combinedResultMap = new Map();

            combinedResults.forEach((response) => {
                response?.data?.markets?.forEach((market) => {
                    let combinedMrktEntitlement = combinedResultMap.get(market?.name)?.combinedMrktEntitlement;
                    let combinedIsReleasable = combinedResultMap.get(market?.name)?.combinedIsReleasable;

                    if (combinedMrktEntitlement?.length) {
                        combinedMrktEntitlement.push(market?.mrkt_entitlements)
                        combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                        combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                        combinedResultMap.set(market?.name, {
                            combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                            combinedIsReleasable: combinedIsReleasable ?? true,
                            others: {...market}
                        })
                    } else {
                        combinedResultMap.set(market?.name, {
                            combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                            combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                            others: {...market}
                        })
                    }
                })
            })

            for (let entry of combinedResultMap.values()) {
                if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    if (sumSelection) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        })
                    } else {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: true,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                        })
                    }
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: true,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                    })
                } else {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: entry.combinedIsReleasable,
                        mrkt_entitlements: entry.combinedMrktEntitlement
                    })
                }

            }
            return {
                "data": {
                    "markets": resultantMarkets // array of markets
                }
            };
        }

        if (combinedResults[0]?.data?.marketDetails?.length) {


            let resultantMarkets: any = [];
            let combinedResultMap = new Map();

            combinedResults.forEach((response) => {
                response?.data?.markets?.forEach((market) => {
                    let combinedMrktEntitlement = combinedResultMap.get(market?.name)?.combinedMrktEntitlement;
                    let combinedIsReleasable = combinedResultMap.get(market?.name)?.combinedIsReleasable;

                    if (combinedMrktEntitlement?.length) {
                        combinedMrktEntitlement.push(market?.mrkt_entitlements)
                        combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                        combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                        combinedResultMap.set(market?.name, {
                            combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                            combinedIsReleasable: combinedIsReleasable ?? true,
                            others: {...market}
                        })
                    } else {
                        combinedResultMap.set(market?.name, {
                            combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                            combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                            others: {...market}
                        })
                    }
                })
            })

            for (let entry of combinedResultMap.values()) {
                if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    if (sumSelection) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        })
                    } else {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: true,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                        })
                    }
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: true,
                        mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.NOT_PRIOR_APPROVAL)
                    })
                } else {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: entry.combinedIsReleasable,
                        mrkt_entitlements: entry.combinedMrktEntitlement
                    })
                }

            }
            return {
                "marketDetails": resultantMarkets
            };
        } else {

            let totalUsMarkets: any = [];
            let totalUsResultMap = new Map();

            combinedResults.forEach((response) => {
                response?.data['Total US']?.forEach((market) => {
                    let combinedMrktEntitlement = totalUsResultMap.get(market?.name)?.combinedMrktEntitlement;
                    let combinedIsReleasable = totalUsResultMap.get(market?.name)?.combinedIsReleasable;

                    if (combinedMrktEntitlement?.length) {
                        combinedMrktEntitlement.push(market?.mrkt_entitlements)
                        combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                        combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                        totalUsResultMap.set(market?.name, {
                            combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                            combinedIsReleasable: combinedIsReleasable ?? true,
                            others: {...market}
                        })
                    } else {
                        totalUsResultMap.set(market?.name, {
                            combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                            combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                            others: {...market}
                        })
                    }
                })
            })

            for (let entry of totalUsResultMap.values()) {
                if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    totalUsMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    if (sumSelection) {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        })
                    } else {
                        totalUsMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: true,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                        })
                    }
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    totalUsMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    totalUsMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    totalUsMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                    })
                } else {
                    totalUsMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: entry.combinedIsReleasable,
                        mrkt_entitlements: entry.combinedMrktEntitlement
                    })
                }

            }

            let byGeographyMarkets: any = [];
            let byGeographyMarketsResultMap = new Map();

            combinedResults.forEach((response) => {
                response?.data['By Geography']?.forEach((market) => {
                    let combinedMrktEntitlement = byGeographyMarketsResultMap.get(market?.name)?.combinedMrktEntitlement;
                    let combinedIsReleasable = byGeographyMarketsResultMap.get(market?.name)?.combinedIsReleasable;

                    if (combinedMrktEntitlement?.length) {
                        combinedMrktEntitlement.push(market?.mrkt_entitlements)
                        combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                        combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                        byGeographyMarketsResultMap.set(market?.name, {
                            combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                            combinedIsReleasable: combinedIsReleasable ?? true,
                            others: {...market}
                        })
                    } else {
                        byGeographyMarketsResultMap.set(market?.name, {
                            combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                            combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                            others: {...market}
                        })
                    }
                })
            })

            for (let entry of byGeographyMarketsResultMap.values()) {
                if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    byGeographyMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    if (sumSelection) {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        })
                    } else {
                        byGeographyMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: true,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                        })
                    }
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    byGeographyMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    byGeographyMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    byGeographyMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                    })
                } else {
                    byGeographyMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: entry.combinedIsReleasable,
                        mrkt_entitlements: entry.combinedMrktEntitlement
                    })
                }

            }

            let channelFMCGMarkets: any = [];
            let channelFMCGMarketsResultMap = new Map();

            combinedResults.forEach((response) => {
                response?.data['Select By Channel']['By Channel FMCG']?.forEach((market) => {
                    let combinedMrktEntitlement = channelFMCGMarketsResultMap.get(market?.name)?.combinedMrktEntitlement;
                    let combinedIsReleasable = channelFMCGMarketsResultMap.get(market?.name)?.combinedIsReleasable;

                    if (combinedMrktEntitlement?.length) {
                        combinedMrktEntitlement.push(market?.mrkt_entitlements)
                        combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                        combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                        channelFMCGMarketsResultMap.set(market?.name, {
                            combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                            combinedIsReleasable: combinedIsReleasable ?? true,
                            others: {...market}
                        })
                    } else {
                        channelFMCGMarketsResultMap.set(market?.name, {
                            combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                            combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                            others: {...market}
                        })
                    }
                })
            })

            for (let entry of channelFMCGMarketsResultMap.values()) {
                if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    channelFMCGMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    if (sumSelection) {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        })
                    } else {
                        channelFMCGMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: true,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                        })
                    }
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    channelFMCGMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    channelFMCGMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    channelFMCGMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                    })
                } else {
                    channelFMCGMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: entry.combinedIsReleasable,
                        mrkt_entitlements: entry.combinedMrktEntitlement
                    })
                }

            }

            let channelSpecialityMarkets: any = [];
            let channelSpecialityMarketsResultMap = new Map();

            combinedResults.forEach((response) => {
                response?.data['Select By Channel']['By Channel Speciality']?.forEach((market) => {
                    let combinedMrktEntitlement = channelSpecialityMarketsResultMap.get(market?.name)?.combinedMrktEntitlement;
                    let combinedIsReleasable = channelSpecialityMarketsResultMap.get(market?.name)?.combinedIsReleasable;

                    if (combinedMrktEntitlement?.length) {
                        combinedMrktEntitlement.push(market?.mrkt_entitlements)
                        combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                        combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                        channelSpecialityMarketsResultMap.set(market?.name, {
                            combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                            combinedIsReleasable: combinedIsReleasable ?? true,
                            others: {...market}
                        })
                    } else {
                        channelSpecialityMarketsResultMap.set(market?.name, {
                            combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                            combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                            others: {...market}
                        })
                    }
                })
            })

            for (let entry of channelSpecialityMarketsResultMap.values()) {
                if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    channelSpecialityMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    if (sumSelection) {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        })
                    } else {
                        channelSpecialityMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: true,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                        })
                    }
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    channelSpecialityMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    channelSpecialityMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    })
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    channelSpecialityMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                    })
                } else {
                    channelSpecialityMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: entry.combinedIsReleasable,
                        mrkt_entitlements: entry.combinedMrktEntitlement
                    })
                }

            }

            return {
                "data": {
                    "Total US": totalUsMarkets,
                    "Select By Channel": {
                        "By Channel FMCG": channelFMCGMarkets,
                        "By Channel Speciality": channelSpecialityMarkets
                    },
                    "By Geography": byGeographyMarkets
                }
            }
        }

    };

    async function isPriorApprovalMarket(req) {
        const res = await http.post(`/suppression/priorApprovedMarket`, req);
        return res.data;
    }

    async function convertCatKeys(categoriesData) {
        const requestBody = {categories: categoriesData};
        const res = await http.post(`/product/convertCatKeys`, requestBody);
        return res.data;
    }

    async function marketSearch(req) {
        const res = await http.post(`/market/search`, req);
        return res;
    }

    async function dodMarketSearch(paramsObj, normalCategories, summedCategories, summedCategoryObjects, sumSelection) {
        if (summedCategoryObjects?.length > 0 && normalCategories?.length > 0) {
            let forceSumSelection = false;
            const normalCatResult = await http.post(`/market/search`, {
                ...paramsObj,
                requiresAllCategoriesApproved: false,
                categories: normalCategories,
            });

            let summedCatResult: any = [];

            const sumSelectionResults = await Promise.all(summedCategoryObjects.map(async (obj) => {
                return await http.post(`/market/search`, {
                    ...paramsObj,
                    requiresAllCategoriesApproved: false,
                    categories: obj.selections || [],
                });
            }));
            let consolidatedResult = [...sumSelectionResults.map(result => {
                return result.data;
            })].flat(Infinity);

            let resultantMarkets: any = [];
            let combinedResultMap = new Map();

            consolidatedResult?.forEach((market) => {
                let combinedMrktEntitlement = combinedResultMap.get(market?.name)?.combinedMrktEntitlement;
                let combinedIsReleasable = combinedResultMap.get(market?.name)?.combinedIsReleasable;

                if (combinedMrktEntitlement?.length) {
                    combinedMrktEntitlement.push(market?.mrkt_entitlements);
                    combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                    combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                    combinedResultMap.set(market?.name, {
                        combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                        combinedIsReleasable: combinedIsReleasable ?? true,
                        others: {...market}
                    });
                } else {
                    combinedResultMap.set(market?.name, {
                        combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                        combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                        others: {...market}
                    });
                }
            });

            consolidatedResult?.forEach((market) => {

                let existsInAllResponse = true;

                sumSelectionResults.forEach((response) => {
                    let marketsList = response?.data?.map((mrkt) => {
                        return mrkt?.name;
                    });
                    if (!marketsList.includes(market?.name)) {
                        existsInAllResponse = false;
                    }
                });

                if (!existsInAllResponse) {
                    let combinedMrktEntitlement = combinedResultMap.get(market?.name)?.combinedMrktEntitlement;
                    let combinedIsReleasable = combinedResultMap.get(market?.name)?.combinedIsReleasable;
                    let others = combinedResultMap.get(market?.name)?.others;

                    combinedMrktEntitlement.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                    combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                    combinedIsReleasable = combinedIsReleasable && false;
                    combinedResultMap.set(market?.name, {
                        combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                        combinedIsReleasable: combinedIsReleasable ?? false,
                        others: {...others}
                    });
                }
            });

            for (let entry of combinedResultMap.values()) {
                if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                    });
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    if (sumSelection) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: true,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                        });
                    }
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    });
                    forceSumSelection = true;
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL],
                    });
                    forceSumSelection = true;
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL],
                    });
                    forceSumSelection = true;
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL],
                    });
                    forceSumSelection = true;
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                    });
                    forceSumSelection = true;
                } else {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: entry.combinedIsReleasable,
                        mrkt_entitlements: entry.combinedMrktEntitlement
                    });
                }

            }
            summedCatResult = {
                "data": resultantMarkets
            };
            return mergeSearchResult(normalCatResult, summedCatResult, forceSumSelection ? forceSumSelection : sumSelection);
        }
        if (summedCategoryObjects?.length > 0 && normalCategories?.length === 0) {

            const sumSelectionResults = await Promise.all(summedCategoryObjects.map(async (obj) => {
                return await http.post(`/market/search`, {
                    ...paramsObj,
                    requiresAllCategoriesApproved: false,
                    categories: obj.selections || [],
                });
            }));
            let consolidatedResult = [...sumSelectionResults.map(result => {
                return result.data;
            })].flat(Infinity);

            let resultantMarkets: any = [];
            let combinedResultMap = new Map();

            consolidatedResult?.forEach((market) => {
                let combinedMrktEntitlement = combinedResultMap.get(market?.name)?.combinedMrktEntitlement;
                let combinedIsReleasable = combinedResultMap.get(market?.name)?.combinedIsReleasable;

                if (combinedMrktEntitlement?.length) {
                    combinedMrktEntitlement.push(market?.mrkt_entitlements);
                    combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                    combinedIsReleasable = combinedIsReleasable && (market?.is_releasable_mrkt ?? true);
                    combinedResultMap.set(market?.name, {
                        combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                        combinedIsReleasable: combinedIsReleasable ?? true,
                        others: {...market}
                    });
                } else {
                    combinedResultMap.set(market?.name, {
                        combinedMrktEntitlement: market?.mrkt_entitlements ?? [],
                        combinedIsReleasable: market?.is_releasable_mrkt ?? true,
                        others: {...market}
                    });
                }
            });

            consolidatedResult?.forEach((market) => {

                let existsInAllResponse = true;

                sumSelectionResults.forEach((response) => {
                    let marketsList = response?.data?.map((mrkt) => {
                        return mrkt?.name;
                    });
                    if (!marketsList.includes(market?.name)) {
                        existsInAllResponse = false;
                    }
                });

                if (!existsInAllResponse) {
                    let combinedMrktEntitlement = combinedResultMap.get(market?.name)?.combinedMrktEntitlement;
                    let combinedIsReleasable = combinedResultMap.get(market?.name)?.combinedIsReleasable;
                    let others = combinedResultMap.get(market?.name)?.others;

                    combinedMrktEntitlement.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                    combinedMrktEntitlement = combinedMrktEntitlement.flat(Infinity);
                    combinedIsReleasable = combinedIsReleasable && false;
                    combinedResultMap.set(market?.name, {
                        combinedMrktEntitlement: Array.from(new Set(combinedMrktEntitlement ?? [])),
                        combinedIsReleasable: combinedIsReleasable ?? false,
                        others: {...others}
                    });
                }
            });

            for (let entry of combinedResultMap.values()) {
                if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                    });
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    if (sumSelection) {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: false,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)]
                        });
                    } else {
                        resultantMarkets.push({
                            ...entry.others,
                            is_releasable_mrkt: true,
                            mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL), MrktEntitlementType.PARTIAL_PRIOR_APPROVAL]
                        });
                    }
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    });
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PRIOR_APPROVAL) && !entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: [...entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), MrktEntitlementType.NOT_PRIOR_APPROVAL]
                    });
                } else if (entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) && entry?.combinedMrktEntitlement?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: false,
                        mrkt_entitlements: entry.combinedMrktEntitlement.filter((val) => val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                    });
                } else {
                    resultantMarkets.push({
                        ...entry.others,
                        is_releasable_mrkt: entry.combinedIsReleasable,
                        mrkt_entitlements: entry.combinedMrktEntitlement
                    });
                }

            }
            return {
                "data": resultantMarkets
            };
        }
        if (summedCategoryObjects?.length === 0 && normalCategories?.length > 0) {
            const res = await http.post(`/market/search`, {
                ...paramsObj,
                requiresAllCategoriesApproved: false,
                categories: normalCategories,
            });
            let resultantMarkets = [];
            if (res?.data?.length) {
                resultantMarkets = res?.data?.map((mrkt) => {
                    let new_mrkt_entitlements: any = [];
                    let new_mrkt_releasability = false;

                    if (mrkt?.mrkt_entitlements.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
                        if (sumSelection) {
                            new_mrkt_entitlements = [MrktEntitlementType.NOT_PRIOR_APPROVAL];
                            new_mrkt_releasability = false;
                        } else {
                            new_mrkt_entitlements = [MrktEntitlementType.PARTIAL_PRIOR_APPROVAL];
                            new_mrkt_releasability = true;
                        }

                        let filtered_mrkt_entitlements = [...mrkt?.mrkt_entitlements].filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL);
                        let updated_mrkt_entitlements = [...Array.from(new Set(filtered_mrkt_entitlements)), ...new_mrkt_entitlements];

                        return {
                            ...mrkt,
                            mrkt_entitlements: updated_mrkt_entitlements,
                            is_releasable_mrkt: new_mrkt_releasability
                        };
                    } else {
                        return {...mrkt};
                    }
                });
            }

            return {...res, "data": resultantMarkets};
        }
    }

    const mergeSearchResult = (normalCatResults, summedCatResults, sumSelection) => {
        const primaryResult = summedCatResults?.data?.length >= normalCatResults?.data?.length ? summedCatResults?.data : normalCatResults?.data;
        const secondaryResult = summedCatResults?.data?.length < normalCatResults?.data?.length ? summedCatResults?.data : normalCatResults?.data;
        const res = primaryResult?.map((market) => {
            let pair_market = secondaryResult?.find((mrkt) => mrkt.name === market.name);

            if (pair_market) {
                let new_mrkt_entitlements: any = [];
                let updated_mrkt_releasability = false;

                if (
                    market?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL) &&
                    pair_market?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL)
                ) {
                    new_mrkt_entitlements.push(MrktEntitlementType.PRIOR_APPROVAL);
                    updated_mrkt_releasability = true;
                } else if (
                    market?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) &&
                    pair_market?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)
                ) {
                    new_mrkt_entitlements.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                    updated_mrkt_releasability = false;
                } else if (
                    market?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL) &&
                    pair_market?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)
                ) {
                    if (sumSelection) {
                        new_mrkt_entitlements.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                        updated_mrkt_releasability = false;
                    } else {
                        new_mrkt_entitlements.push(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL);
                        new_mrkt_entitlements.push(MrktEntitlementType.DISABLE_STACK_BY_IN_DOD);
                        updated_mrkt_releasability = true;
                    }
                } else if (
                    market?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) &&
                    pair_market?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL)
                ) {
                    if (sumSelection) {
                        new_mrkt_entitlements.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                        updated_mrkt_releasability = false;
                    } else {
                        new_mrkt_entitlements.push(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL);
                        new_mrkt_entitlements.push(MrktEntitlementType.DISABLE_STACK_BY_IN_DOD);
                        updated_mrkt_releasability = true;
                    }
                } else if (
                    (market?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL) &&
                        pair_market?.mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) ||
                    (market?.mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) &&
                        pair_market?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL))
                ) {
                    if (sumSelection) {
                        new_mrkt_entitlements.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                        updated_mrkt_releasability = false;
                    } else {
                        new_mrkt_entitlements.push(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL);
                        updated_mrkt_releasability = true;
                    }

                } else if (
                    (market?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) &&
                        pair_market?.mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) ||
                    (market?.mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) &&
                        pair_market?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL))
                ) {
                    if (sumSelection) {
                        new_mrkt_entitlements.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                        updated_mrkt_releasability = false;
                    } else {
                        new_mrkt_entitlements.push(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL);
                        updated_mrkt_releasability = true;
                    }
                } else if (
                    (market?.mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL) &&
                        pair_market?.mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL))
                ) {
                    if (sumSelection) {
                        new_mrkt_entitlements.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                        updated_mrkt_releasability = false;
                    } else {
                        new_mrkt_entitlements.push(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL);
                        updated_mrkt_releasability = true;
                    }
                } else {
                    updated_mrkt_releasability = true;
                    new_mrkt_entitlements = [];
                }

                let filtered_mrkt_entitlements = [...market?.mrkt_entitlements, ...pair_market?.mrkt_entitlements].filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                let updated_mrkt_entitlements = [...Array.from(new Set(filtered_mrkt_entitlements)), ...new_mrkt_entitlements]

                return {
                    ...market,
                    mrkt_entitlements: updated_mrkt_entitlements,
                    is_releasable_mrkt: updated_mrkt_releasability
                };

            } else {
                let new_mrkt_entitlements: any = [];
                let updated_mrkt_releasability = false;

                if (
                    market?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)
                ) {
                    new_mrkt_entitlements.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                    updated_mrkt_releasability = false;
                }

                if (
                    market?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL)
                ) {
                    if (sumSelection) {
                        new_mrkt_entitlements.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                        updated_mrkt_releasability = false;
                    } else {
                        new_mrkt_entitlements.push(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL);
                        new_mrkt_entitlements.push(MrktEntitlementType.DISABLE_STACK_BY_IN_DOD);
                        updated_mrkt_releasability = true;
                    }
                }

                if (
                    market?.mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                ) {
                    if (sumSelection) {
                        new_mrkt_entitlements.push(MrktEntitlementType.NOT_PRIOR_APPROVAL);
                        updated_mrkt_releasability = false;
                    } else {
                        new_mrkt_entitlements.push(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL);
                        new_mrkt_entitlements.push(MrktEntitlementType.DISABLE_STACK_BY_IN_DOD);
                        updated_mrkt_releasability = true;
                    }
                }

                let filtered_mrkt_entitlements = [...market?.mrkt_entitlements ?? [], ...pair_market?.mrkt_entitlements ?? []].flat(Infinity).filter((val) => val !== MrktEntitlementType.PRIOR_APPROVAL && val !== MrktEntitlementType.NOT_PRIOR_APPROVAL && val !== MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)
                let updated_mrkt_entitlements = [...Array.from(new Set(filtered_mrkt_entitlements)), ...new_mrkt_entitlements]


                return {
                    ...market,
                    mrkt_entitlements: updated_mrkt_entitlements,
                    is_releasable_mrkt: updated_mrkt_releasability
                };
            }

        });

        return {data: res};
    };

    async function marketAncestry(marketKeyword, marketSelType) {
        const res = http.get(`/market/ancestry`, {
            params: {
                rawMarketName: marketKeyword,
                mrktSelType: marketSelType,
            },
        });
        return await res;
    }

    async function getSignupProperties() {
        return httpGet(`/users/signUpProperties`);
    }

    async function productAncestry(path) {
        const res = http.get(`/product/ancestry?${path}`);
        return await res;
    }

    async function brandSearch(
        searchKeyword: string,
        {categories, returnAllHits}: { categories?: string[]; returnAllHits?: boolean }
    ) {
        try {
            const res = await httpPost(`/product/search/brandOnly/${searchKeyword}`, {
                categories,
                returnAllHits,
            });
            return res ?? {};
        } catch (err) {
            return {};
        }
    }

    async function findBrandsForCategories(searchText: string, categories?: string[]) {
        const {brands} = await httpPost(`/product/search/brandOnly/${encodeURIComponent(searchText)}`, {
            categories,
        });

        return brands;
    }

    async function manufacturerForCategoriesSearch(requestBody) {
        try {
            const res = await http.post(`/product/search/manufacturerForCategories`, requestBody);
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function manufacturerSearch(searchKeyword) {
        try {
            const res = await http.get(`/product/search/manufacturer/${searchKeyword}`);
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function parentCompanySearch(searchKeyword) {
        try {
            const res = await http.get(`/product/search/parentCompany/${searchKeyword}`);
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function submitUpcCodingRequest(upcInfo) {
        return httpPost('/item', {
            ...upcInfo,
        });
    }

    async function categorySearch(searchKeyword) {
        const res = http.get(`/product/search/categoryOnly/${searchKeyword}`);
        return await res;
    }

    async function fetchBrandList(requestBody) {
        try {
            const res = await http.post(`/product/brandsForAll`, requestBody);
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function getCatalogByItemName(catalogItem) {
        const {data = []} = await http.get(`/products/catalog/${catalogItem}`);
        return data[0];
    }

    async function getCategoriesForBrands(brands = []) {
        if (!brands.length) {
            return [];
        }

        const {categories} = await httpPost('/product/categoryForBrand', {
            brands,
        });

        return categories.map(({name}) => name);
    }

    async function getCategoryByBrand(brand) {
        try {
            const res = await http.get(`/product/categoryForBrand`, {
                params: {
                    brand,
                },
            });
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function getCategoriesByBrands(brands: string[]): Promise<string[]> {
        // filterCategoriesOnBrands
        const {categories} = await httpPost(`/product/filter/categories`, {
            brands,
        });
        return categories.map((category) => category.name);
    }

    async function getCategoriesByManufacturers(manufacturers: string[]): Promise<string[]> {
        // filterCategoriesOnManufacturers
        const {categories} = await httpPost(`product/search/categoriesForManufacturer`, {
            manufacturers,
        });
        return categories;
    }

    async function getCategoriesWithinBrand(brand, requestBody) {
        try {
            const res = await http.post(`/product/categoryForBrand`, requestBody, {
                params: {
                    brand,
                },
            });
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function searchBrandForProductSet(requestBody) {
        try {
            const res = await http.post(`/product/search/characteristic/brand`, requestBody);
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function getAdvancedMarketFilters() {
        try {
            const res = await http.get(`/market/filterValues`);
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function getMarketsByAdvancedFilters(requestBody) {
        try {
            const res = await http.post('/market/marketsByFilter', requestBody);
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function getMarketsByAdvancedFiltersForDodSelections(paramsObj: {
        reportType: string
    }, normalCategories: string[], summedCategories: string[], summedCategoryObjects: any[], sumSelection: boolean) {
        try {
            /**
             * note: we have advancedSearch() also to change and verify
             * hmm seems mergeResult method handles it, just verifying once with the response structure should be fine
             * logic will be same
             */
            if (summedCategories?.length > 0 && normalCategories?.length > 0) {
                const [normalCatResult, summedCatResult] = await Promise.all([
                    http.post(`/market/marketsByFilter`, {
                        ...paramsObj,
                        requiresAllCategoriesApproved: false,
                        categories: normalCategories,
                    }),
                    http.post(`/market/marketsByFilter`, {
                        ...paramsObj,
                        requiresAllCategoriesApproved: true,
                        categories: summedCategories,
                    }),
                ]);
                return mergeResult(normalCatResult, summedCatResult, sumSelection);
            }

            if (summedCategories?.length > 0 && normalCategories?.length === 0) {
                const res = await http.post(`/market/marketsByFilter`, {
                    ...paramsObj,
                    requiresAllCategoriesApproved: true,
                    categories: summedCategories,
                });
                return res && res.data ? res.data : {};
            }

            if (summedCategories?.length === 0 && normalCategories?.length > 0) {
                let res;
                if (sumSelection) {
                    res = await http.post('/market/marketsByFilter', {
                        ...paramsObj,
                        requiresAllCategoriesApproved: true,
                        categories: normalCategories,
                    });
                } else {
                    res = await http.post('/market/marketsByFilter', {
                        ...paramsObj,
                        requiresAllCategoriesApproved: false,
                        categories: normalCategories,
                    });
                }

                if (res.data.marketDetails) {
                    const newResult = res.data.marketDetails.map((market) => {
                        if (Array.isArray(market?.mrkt_entitlements)) {
                            return {
                                ...market,
                                'mrkt_entitlements': [...market?.mrkt_entitlements]
                            }
                        } else {
                            return {
                                ...market
                            }
                        }

                    })
                    return {
                        "marketDetails": newResult
                    }

                }

            }
        } catch (err) {
            return {};
        }
    };

    async function findBrandsByCategories({
                                              categories, searchValue,
                                          }: {
        categories: string[];
        searchValue: string;
    }) {
        return httpPost(`/product/search/characteristic/brand`, {categories, input_text: searchValue});
        // return res && res.data ? res.data : {};
    }

    //for brand only=omni
    // TODO: remove when ByzzerSearch is removed
    /**
     * @deprecated
     * @param requestBody
     */
    async function searchBrandForFocusProduct(requestBody) {
        return httpPost(`/product/search/omni/brandForCategories`, requestBody);
    }

    async function findOmniBrandsInCategories(categories: string[], search_term: string) {
        return httpPost(`/product/search/omni/brandForCategories`, {
            categories,
            search_term,
        });
    }

    async function findOmniBrandsFamilyInCategories(categories, search_term: string) {
        return httpPost(`/product/search/omni/brandFamilyForCategories`, {
            categories,
            search_term,
        });
    }

    async function findOmniBrandLowForCategories(categories, search_term: string) {
        return httpPost(`/product/search/omni/brandLowForCategories`, {
            categories,
            search_term,
        });
    }

    async function searchManufacturerForFocusProduct(requestBody) {
        return httpPost(`/product/search/omni/manufacturerForCategories`, requestBody);
    }

    async function findManufacturersInCategories(searchText: string, categories: string[] = []): Promise<string[]> {
        const {manufacturers} = await httpPost('/product/search/omni/manufacturerForCategories', {
            categories,
            search_term: searchText,
        });

        return manufacturers;
    }

    async function getProductCharacteristics(requestBody) {
        try {
            const res = await http.post(`/product/search/characteristic/multiCategories`, requestBody);
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function getProductChars(requestBody): Promise<LegacyCharacteristic[]> {
        return httpPost(`/product/characteristics`, requestBody);
    }

    // Use this method to fetch characteristics instead of getProductChars()
    async function getCharacteristicsForCategories(categories: string[]): Promise<Characteristic[]> {

        const {characteristics} = await httpPost(`/product/characteristics`, {
            categories: categories.map((item) => ({
                hierarchy: 'CATEGORY',
                name: item,
            })),
            themePurchaseType: 'subscription_addon_characteristic_theme',
        });
        return characteristics.map(char => ({
            code: char.chr_code,
            displayName: char.chr_display_name
        }));
    }

    //this method can be used to fetch characteristics values instead of getCharacteristicValues()
    async function getCharacteristicValuesForCategories(
        categories: string[],
        characteristicCode: string
    ): Promise<string[]> {
        const requestBody = {
            categories: categories.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            }),
            charCode: characteristicCode,
        };
        return httpPost(`/product/characteristicValues`, requestBody);
    }

    async function getCharacteristicValues(requestBody) {
        return httpPost(`/product/characteristicValues`, requestBody);
    }

    async function getUPCForCategories(search_term: string, brand?: string, categories?: string[], maxResults?: number): Promise<UPCDetails | UPCSearchError | undefined> {
        try {
            const res = await http.post(`/product/search/upc`, {
                search_term,
                brand,
                categories,
                maxResults // default is defined on server
            });

            if (res?.data?.error) { // todo: rework this to follow restful pattern
                return res?.data;
            }

            return Boolean(res?.data?.upc_details) && Boolean(res?.data?.upc_details?.length) ? res.data : {upc_details: []};
        } catch (err) {
            // return {}; TODO: can't return empty object, add error handler
            return;
        }
    }

    // TODO: Check this endpoint to see if it still returns the right information.
    async function getCompanyPreferences() {
        let preferences = {categories: [], omniCategories: []};
        try {
            const resp = await httpGet(`/company/preferences`);
            preferences = resp?.configJson ?? preferences;
        } catch (err) {
            // do nothing and keep it moving
        }

        return preferences;
    }

    async function createTenantCompanyProductConfig(
        preferences: {initialBrands: string[]; brandNotRetailingYet: boolean; categories: string[]}
    ): Promise<{
        brandNotRetailingYet?: boolean;
        categories: string[];
        brands?: string[];
    }> {
        //This will be changed to change the scope.
        return httpPost(`/company/preferences`, {
            configJson: preferences,
        });
    }

    async function updateUser(id, updates) {
        return httpPatch(`/users/${id}`, {
            ...updates,
            id: undefined,
            email: undefined,
        });
    }

    async function getAdmins() {
        return httpGet('/company/admins');
    }

    async function getTeamMembers(): Promise<TeamMember[]> {
        // Note: This excludes setup admins
        const teamMembers: TeamMember[] = await httpGet('/team_members');
        return teamMembers?.map((member) => {
            return {
                ...member,
                // logic should include multi-tenancy check as well, or rename this flag to something else, e.g. domainNotInCompany
                isConsultant: !emailDomainFoundInDomainList(member?.email, nsCompany?.domains) ?? false
            };
        }) ?? []
    }

    async function updateTeamMember(id, updates) {
        return httpPatch(`/team_members/${id}`, updates);
    }

    async function getTeams(): Promise<Team[]> {
        const teamsResp: Team[] = await httpGet(`/teams`);
        return teamsResp?.map((team) => {
            return {
                ...team,
                // Todo: See if this can/should be done on the backend
                title: team?.displayName,
            }
        })
    }

    async function createTeam(team: { displayName: string; isDefault?: Boolean; }) {
        return httpPost(`/teams`, team);
    }

    async function updateTeam(id, updates) {
        return httpPatch(`/teams/${id}`, updates);
    }

    async function deleteTeam(id) {
        return httpDelete(`/teams/${id}`);
    }

    async function deleteCompanyUser(userDetail) {
        try {
            const res = await http.delete(`/company/team/member/`, userDetail);
            return res;
        } catch (err) {
            return [];
        }
    }

    async function inviteUser(invitee: UserInvite, domainMismatchReasonCode?: DomainMismatchReasonCode) { // todo: add return type 
        return httpPost(`/invitations/user`, {invitee, domainMismatchReasonCode});
    }

    async function addExternalUserToCompany(externalUser: ExternalUser): Promise<void> {
        return httpPost(`/invitations/external_user`, {externalUser});
    }

    async function getCompanyMultiTenancyForUserEmail(email: string): Promise<{
        userFound: boolean;
        multiTenantEnabled?: boolean;
        domainInUse?: boolean;
        domainInCurrentCompanyDomains?: boolean;
        domainInPrimaryCompanyDomains?: boolean;
    }> {
        return httpPost(`/invitations/multi_tenancy`, {email});
    }


    async function inviteUsers(invitees: UserInvite[], domainMismatchReasonCode?: DomainMismatchReasonCode) {
        return httpPost(`/invitations.bulk_create`, {invitees, domainMismatchReasonCode} );
    }

    async function removeInvitation(id) {
        return httpDelete(`/invitations/${id}`);
    }

    async function removeUser(id) {
        return httpDelete(`/users/${id}`);
    }

    async function approveUser({userId, role, teamId}) {
        return httpPost('/users.approve', {
            userId,
            role,
            teamId,
        });
    }
    /**
     * @deprecated Use getDefaultRunConfig instead, but your firstest choice should be using defaultRunConfig from UserContext
     * This is only being kept to support the existing functionality.  The endpoint/service will check for the existence of a refactored format first and if not found, will return a converted legacy format.
     */
    async function getUserPreferences(): Promise<UserRunConfig | undefined> {
        try {
            const res = await http.get(`/users/preferences`);
            return res?.data || {};
        } catch (err) {
            return {};
        }
    }

    async function getDefaultRunConfig(): Promise<DefaultRunConfig> {
        const defaultRunConfig = await httpGet('/me/default_run_config'); // todo: see if this route name can be improved

        return defaultRunConfig;
    }

    async function saveUserRunConfig(runConfig: UserRunConfig): Promise<DefaultRunConfig> {
        const response = await httpPost(`/users/preferences`, { 
            runConfig,
        });
        return response;
    }

    async function saveFreeUserDefaultRunConfig(userSelections?: FreeUserDefaultRunConfig): Promise<DefaultRunConfig> {
        return httpPost('/users/free_user_preferences', {userSelections});
    }

    async function saveUserDefaultRunConfig(runConfig: DefaultRunConfig): Promise<DefaultRunConfig> {
        const response = await httpPost(`/users/default_run_config`, {
            runConfig,
        });
        return response;
    }

    async function setCompanyAlerts(requestBody) {
        return httpPost(`/company/alerts`, requestBody);
    }

    async function getCompanyAlerts() {
        return httpGet(`/company/alerts`);
    }

    async function getSubscription() {
        const contracts = await httpGet(`/products/purchasedProducts`, {
            params: {
                type: 'subscription',
            },
        });
        //TODO: Move to server and only return the reports.
        const contract = contracts?.[0];
        const reports: any[] = [];
        const alerts: any[] = [];
        const subscription = contract?.catalogItems?.[0];
        const sku = subscription?.sku;
        let configuredAlertsCnt = 0;
        let packageType = subscription?.title;
        // let sku = 0;

        contract?.catalogItems?.forEach((item) => {
            item.productBundles?.forEach((bundle) => {
                bundle.productBundleProducts?.forEach((pbp) => {
                    pbp.products?.forEach((product) => {
                        if (product.type === 'report') {
                            reports.push(product);
                        } else if (product.type === 'alert') {
                            if (bundle.productActionType === 'configure') {
                                configuredAlertsCnt += bundle.productActionQuantity;
                            }
                            alerts.push(product);
                        }
                    });
                });
            });
        });

        return {
            reports,
            alerts,
            configuredAlertsCnt,
            packageType,
            sku,
        };
    }

    async function sendResetPasswordLink(email) {
        return httpPost(`/users/resetPassword`, {
            email,
        });
    }

    async function acceptInvitation(acceptance) {
        return httpPost(`/invitations.accept`, acceptance);
    }

    async function findCategoriesForBrand(brand) {
        const {categories} = await httpGet(`/product/categoryForBrand`, {
            params: {
                brand,
            },
        });
        return categories;
    }

    /**
     * httpGet business questions data based on id's passed
     * @param  bArray role ids  @type array
     * @returns Array of business question values associated to role ids passed
     */

    function getBusinessQuestionValues(bArray) {
        const response = [];
        if (bArray?.length > 0) {
            return bArray.map((val, index, array) => {
                return businessQuestions.find((q) => q.key === val)?.value;
            });
        }
        return response;
    }

    /**
     * httpGet Roles data based on id's passed
     * @param  rArray role ids  @type array
     * @returns Array of role values associated to role ids passed
     */
    function getRolesValues(rArray) {
        const response = [];
        if (rArray?.length > 0) {
            return rArray.map((val, index, array) => {
                return productSkuFeaturesRoles.find((q) => q.key === val)?.role;
            });
        }
        return response;
    }

    /**
     * httpGet product information based on product sku
     * @param  sku product sku
     * @returns status @value true or false
     * @returns data @value [] or @productSkuInfo
     */
    function getProductInformation(productSku) {
        const response: any = {status: false, data: null};
        if (productSku) {

            const tempProductsList = [...getAllReports(), ...getAllAlerts(), ...getAllStories()];
            const filteredProduct = tempProductsList.find(({sku}) => sku === productSku);
            const info = filteredProduct?.metadata?.reportOptions || {};
            const productFilters = filteredProduct?.reportFilterOptions || [];

            if (info) {
                const responseData = {
                    sku: productSku,
                    businessQuestions: productFilters.filter((val) => val.code === 'business_question')[0]?.filters,
                    roles: productFilters.filter((val) => val.code === 'roles')[0]?.filters,
                    type: reportTypes.find((types) => types.key === info.type)?.value,
                    features: productFilters.filter((val) => val.code === 'key_features')[0]?.filters ?? info.features,
                    overview: filteredProduct?.overview || "",
                    imageUrl: '',
                    measures: '',
                    cadence: productFilters.filter((val) => val.code === 'cadence')[0]?.filters,
                };
                response.status = true;
                response.data = responseData;
            }
        }
        return response;
    }

    /**
     * Get all available products by type
     * @returns Product with title,description and configurations
     */
    async function getAllProductsByType(type) {
        return httpGet(`/products/byCatalogType/${type}`);
    }

    /**
     * Alert with be created against to user
     * @param  request alert configuration
     * @returns
     */
    async function createAlerts(sku: string, alertRunConfig: AlertRunConfig): Promise<any> {
        return httpPost('/me/alerts', {
            sku,
            alertRunConfig,
        });
    }

    // async function createAlerts(request) {
    //     try {
    //         return httpPost('/me/alerts', request);
    //     } catch (err) {
    //         return [];
    //     }
    // }

    /**
     * Alert will be updated with id provided
     * @param  request alert configuration with id
     * @returns
     */
    async function updateAlerts(userAlertId: any, sku: string, alertRunConfig: AlertRunConfig): Promise<any> {
        return httpPatch('/me/alerts', {
            userAlertId,
            sku,
            alertRunConfig,
        });
    }

    /**
     * Saved alerts against to  user will be retrieved
     * @returns
     */
    async function getConfiguredAlerts(): Promise<UserAlertSummary[]> {
        const configuredAlerts = await httpGet('/me/alerts');
        const defaultDateFormat = 'yyyy-MM-dd';

        return configuredAlerts.map((configuredAlert) => {
            return {
                ...configuredAlert,
                alertProduct: getProductBySku(configuredAlert.productSku),
                updatedDtm: configuredAlert?.updatedDtm && moment(configuredAlert?.updatedDtm).isValid()
                ? format(new Date(configuredAlert?.updatedDtm), defaultDateFormat)
                : null
            }
        }) as UserAlertSummary[];
    }

    /**
     * Remove a single alert for the current user
     * @param id
     * @returns {Promise<*>}
     */
    async function deleteAlert(id) {
        return httpDelete(`/my/alerts/${id}`);
    }

    /**
     * Get Top 10 Markets for Brand
     * @param  brand  string
     */
    async function getTopTenMarketsForBrand(brand) {
        try {
            return httpGet(`/ranking/topBrandMarkets/${encodeURIComponent(brand)}`);
        } catch (err) {
            return err;
        }
    }

    /**
     * Get Top 10 Categories for Brands
     * @param brands string[]
     */
    async function getTopCategoriesForBrands(
        brands: string[],
        rankLimit: number = Infinity
    ): Promise<{ topCategories: TopCategory[] } | any> {
        // todo: rename to remove Ten and add parameter to accept number limit instead
        try {
            const topCategoriesForBrands = await httpPost(`/ranking/topCategories`, {brands}); // todo: change to httpGet
            const topRankedCategoriesForBrands = topCategoriesForBrands.topCategories.filter(
                (topCategory) => topCategory.categoryRank <= rankLimit
            );

            return {topCategories: topRankedCategoriesForBrands};
        } catch (err) {
            return err;
        }
    }

    /**
     * Get Top 10 Markets for Category
     * @param  brand  string
     */
    async function getTopTenMarketsForCategory(brand) {
        try {
            return httpGet(`/ranking/topCategoryMarkets/${encodeURIComponent(brand)}`);
        } catch (err) {
            return err;
        }
    }

    /**
     * Get Top 10 Brands for Category
     * @param  brand  string
     */
    async function getTopTenBrandsForCategory(brand) {
        try {
            return httpGet(`/ranking/topBrands/${encodeURIComponent(brand)}`);
        } catch (err) {
            return err;
        }
    }

    /**
     * Panel Releasability for Categories
     * @param  request Panel Releasability
     * @returns
     */
    async function PanelReleasabilityForCategory(request) {
        try {
            return httpPost('/ranking/panelReleasability/categories', request);
        } catch (err) {
            return err;
        }
    }

    /**
     * Get Panel Releasability for Brand
     * @param  brand  string
     */
    async function PanelReleasabilityForBrand(brand) {
        try {
            return httpGet(`/ranking/panelReleasability/brand/${encodeURIComponent(brand)}`);
        } catch (err) {
            return err;
        }
    }

    async function getPriorApprovalStatus() {
        try {
            return httpGet(`/company/priorApprovals/`);
        } catch (err) {
            return err;
        }
    }

    async function getPriorApprovalRetailers() {
        try {
            return httpGet(`/company/pmaParentRetailerList/`);
        } catch (err) {
            return err;
        }
    }

    async function calculateScores(type, criteria) {
        try {
            let newCriteria = buildScoresCriteria(criteria);
            return httpPost(`/actions/calculate_scores/${type}`, newCriteria);
        } catch (err) {
            return err;
        }
    }

    async function calculateScoresCount(type, criteria) {
        try {
            let newCriteria = buildScoresCriteria(criteria);
            return httpPost(`/actions/${type}/count`, newCriteria);
        } catch (err) {
            return err;
        }
    }

    function buildScoresCriteria(
        criteria: Partial<Criteria> | Partial<Criteria>[]
    ): Partial<Criteria> | Partial<Criteria>[] {
        if (criteria) {
            if (Array.isArray(criteria) && criteria.length > 0) {
                return criteria.map((criterion) => buildCriteria(criterion));
            } else if (!Array.isArray(criteria)) {
                return buildCriteria(criteria);
            }
        }
        return criteria;
    }

    function buildCriteria(criteria: Partial<Criteria>): Partial<Criteria<string[]>> {
        let newCriteria: Partial<Criteria<string[]>> = {};
        for (const key in criteria) {
            if (Array.isArray(criteria[key])) {
                newCriteria[key] = criteria[key];
            } else {
                newCriteria[key] = [criteria[key]];
            }
        }
        return newCriteria;
    }

    async function overallScoreTrends(selections) {
        try {
            let newCriteria = buildScoresCriteria(selections);
            return httpPost(`/actions/overall_trend`, newCriteria);
        } catch (err) {
            return err;
        }
    }

    /**
     * Append an audit record.  Normally this is not necessary as all API calls
     * are already audited, but in case the UI wants to audit something that does
     * not use an API.
     *
     * @param who: string
     * @param when: Date
     * @param action: string (POST, GET, PUT, DELETE, *.  Anything other than the 4
     * main HTTP verbs will httpGet httpPut into the audit record as "X-OriginalAction" in
     * the metadata.)
     * @param what: object
     * @return {Promise<void>}
     */
    async function appendAudit(who, when, action, what) {
        try {
            await http.post('audit', {
                audit_who: who,
                audit_when: when || new Date(),
                audit_action: action,
                audit_body: what,
            });
        } catch (err) {
            // It's audit, nothing we should (or can) do on an error.
            console.log(err);
        }
    }

    /**
     * to fetch remaining market information based on markets array
     * @param market as array
     */
    async function getRemainingMarkets(markets) {
        try {
            return httpPost('/market/marketsByDispName', {markets});
        } catch (err) {
            return err;
        }
    }

    async function getPurchasedThemes(type) {
        return httpGet(`/reference_data/my_products_and_skus?type=${type}`);
    }

    async function getCategoriesForTheme(themeSku) {
        return httpGet(`/reference_data/theme_categoriess?sku=${themeSku}`);
    }

    async function getCharsForThemeAndCategories(themeSku, categories) {
        return await httpGet(`/reference_data/theme_characteristics`, {
            params: {
                categories,
                sku: themeSku,
            },
        });
    }

    /** Return panel releasability for selected category & brand */
    async function getCPSPanelReleasability(category, brand = []) {
        return httpPost(`/product/checkPanelReleasability`, {
            brands: brand,
            categories: category,
        });
    }

    async function getFactConfig() {
        return await httpGet(`/data_on_demand/facts_list`);
    }

    /** Return Data On Demand Report History List in array */
    async function getExtracts( signal?: AbortSignal | undefined ): Promise<ExtractRun[]> {
        try {
          return httpGet(
              '/extracts',
              {
                  signal: signal,
              }
          );
        } catch (err) {
            throw err;
        }
    }

    async function getExtractById(id): Promise<DodRunConfig> {
        return httpGet(`/extracts/${id}`);
    }

    async function getScheduledExtractById(id) {
        return await httpGet(`/extracts/scheduled/${id}`);
    }

    /** JSON File As Response */
    async function getExtractSignedUrl(extractId, layoutId, opType) {
        try {
            return await httpGet(`/data_on_demand/download/${extractId}/${layoutId}/${opType}`);
        } catch (err) {
            throw err;
        }
    }

    /** JSON File As Response */
    async function getExtractSignedUrls(extractIds, layoutIds, opType) {
        try {
            return await httpPost(`/data_on_demand/download`, {
                extractIds,
                item: layoutIds,
                type: opType
            });
        } catch (err) {
            throw err;
        }
    }

    async function getExtractDataPoints({ExtractSelection, ExtractLayout}) {
        try {
            dpAbortController.abort();
            dpAbortController = new AbortController();
            return httpPostConfig(
                `/data_on_demand/data_points`,
                {
                    ExtractSelection,
                    ExtractLayout,
                },
                {
                    signal: dpAbortController.signal,
                }
            );
        } catch (err) {
            throw err;
        }
    }

    /** Return Data On Demand Report History List in array */
    async function deleteExtractReport(id) {
        return await httpDelete(`/data_on_demand/${id}`);
    }

    async function deleteMultipleExtractReports(ids) {
        return await httpDelete(`/data_on_demand`, {
            data: {
                ids
            }
        });
    }

    /** Return Data On Demand Report History List in array */
    async function runExtractReport({
                                        reportName, reporSize, ExtractLayout, ExtractSelection, id, extractId, jobId,
                                    }) {
        return await httpPost(`/data_on_demand`, {
            reportName,
            reporSize,
            ExtractLayout,
            ExtractSelection,
            id,
            extractId,
            jobId,
        });
    }

    async function runExtractLayoutChange({
                                              reportName,
                                              reportSize,
                                              ExtractLayout,
                                              ExtractSelection,
                                              id,
                                              extractId,
                                              jobId,
                                          }) {
        return await httpPost(`/data_on_demand/create_extract_hack_layout`, {
            reportName,
            reportSize,
            ExtractLayout,
            ExtractSelection,
            id,
            extractId,
            jobId,
        });
    }

    /* Return retailer hierarchies of products at different levels */
    async function retailerHierarchies(postData) {
        return httpPost(`/me/retailerHierarchies`, {
            ...postData,
        });
    }

    async function getCategoryHierarchyTree() {
        const {Tree} = await httpPost('/me/retailerHierarchies', {
            hierarchyType: 'tree',
        });

        return Tree;
    }

    /* Return the markes specific to the subsciption of the account */
    async function getSubscriptionSpecificMarkets(marketGroup, requestBody) {
        return httpPost(`/market/accessibleMarkets`, requestBody);
    }

    async function getOmniMarkets(
        // datatype: ReportDatatype = "omni",
        runType: RunType,
        categories: string[] = []
    ) {
        // console.log("API - getOmniMarkets fired - 'runType', 'categories' ===>> ", runType, categories)

        const response = await httpPost(`/market/omni`, {
            categories,
            reportType: runType,
            mrktGrp: 'OMNI', // datatype.toUpperCase()
        });

        const reshapedOmniMarkets = response.map((market) => {
            return {
                name: market.name,
                key: market.mrkt_key,
                isOpen: market.is_releasable_mrkt,
                missingPriorApproval: market.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL),
                requiresPurchase: market.mrkt_entitlements?.includes(MrktEntitlementType.NOT_REV_SHARE),
                categoryNotCovered: market.mrkt_entitlements?.includes(MrktEntitlementType.CATEGORY_NOT_COVERED),
                masterCompany: market.master_company,
                marketType: market?.sub_mrkt_type
                // parentRetailerId: market.parent_retailer_id
            };
        });

        return reshapedOmniMarkets;
    }

    async function buildStories(requestBody) {
        try {
            const res = await http.post(`/story/generateStory`, requestBody);
            return res;
        } catch (error) {
            return error;
        }
    }

    async function getMyStorySubscription() {
        const storySubscription = await httpGet('/my/subscription');
        infinitizeUsage(storySubscription.usage);
        return storySubscription;
    }

    async function savePersonas(personaPayload: PersonaPayload): Promise<PersonaPayload> {
        return httpPost('/my/personas', personaPayload);
    }

    async function getMyStoryObjectiveList(productSku) {
        try {
            const resp = httpGet(`/story/getStoryObjectives?productSku=${productSku}`);
            return await resp;
        } catch (err) {
            return err;
        }
    }

    async function downloadStory(key: string, responseType: ResponseType = 'json') {
        const data = await httpPost(
            `/story/download`,
            {
                storyKey: key,
            },
            { responseType }
        );
        const expectedContentType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        if (data.type === expectedContentType) {
            const storySignedURL = data.url;
            let tempLink = document.createElement('a');
            tempLink.href = storySignedURL;
            tempLink.click();
            // const blob = new Blob([data]);
            // downloadStoryResources(blob, 'pptx', 'story');
        } else if (data.type !== 'application/json') {
            const blob = new Blob([data], {type: 'application/zip'});
            // @ts-ignore
            downloadStoryResources(blob);
        } else {
            return { status: 'Unable to process your request' }; 
        }
    }

    const downloadStoryResources = (blob, fileType: string = 'zip', fileName: string = 'story') => {
        var csvURL = window.URL.createObjectURL(blob);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        if (fileType == 'pptx') {
            tempLink.download = fileName + '.pptx';
        } else {
            tempLink.setAttribute('download', fileName + '.zip');
        }
        tempLink.click();
    };

    function infinitizeUsage(usage = {}) {
        for (let entry of Object.values(usage)) {
            // @ts-ignore
            if (entry.limit === null) {
                // @ts-ignore
                entry.limit = Infinity;
            }
        }
    }

    async function getParentCompanySearch(requestBody) {
        return httpPost(`/product/search/parentCompany`, requestBody);
    }

    async function getManufacturerSearch(requestBody) {
        return httpPost(`/product/search/manufacturer`, requestBody);
    }

    async function getBrandsSearch(requestBody) {
        return httpPost(`/product/search/brand`, requestBody);
    }

    async function getUpcDescriptionSearch(requestBody) {
        return httpPost(`/product/search/upcDescriptionWithFilters`, requestBody);
    }

    async function getProductDescription(requestBody) {
        return httpPost(`/product/search/description`, requestBody);
    }

    async function deleteStoryHistory(storyId) {
        try {
            const resp = httpGet(`/story/deleteStory?storyId=` + storyId);
            return await resp;
        } catch (err) {
            return err;
        }
    }

    async function getStoryNames() {
        try {
            const resp = httpGet(`/story/getStoryNames?actionType=story`);
            return await resp;
        } catch (err) {
            return err;
        }
    }

    async function marketSearchByMrktType(req) {
        const res = await http.post(`/market/marketsByMrktType`, req);
        return res;
    }

    async function marketSearchByAccount(req) {
        const res = await http.post(`/market/accountMarkets`, req);
        return res;
    }

    async function getMarketDispNames(req) {
        const res = await http.post(`/market/marketDispNames`, {marketKeys: req});
        if (res) return res?.data;
    }

    async function getCategoryCount(requestBody) {
        return httpPost(`/product/categoriesCount`, requestBody);
    }

    async function getHandlebarsTemplate(id) {
        try {
            const res = await http.get(`products/getAlertTemplateDataByAlertRunId/${id}`);
            return { status: 'success', alert: res.data }; // reverting this from a prior commit's value
        } catch (error) {
            return {status: 'error', message: JSON.stringify(error)};
        }
    }

    async function getEntitledMarketTypes(req) {
        const res = await http.post(`/market/entitledMarketTypes`, req);
        return res;
    }

    async function getStoriesHistory(requestBody) {
        try {
            if (requestBody.runFrom !== undefined && requestBody.runFrom !== null && requestBody.runFrom !== '') {
                let runFromDate = new Date(requestBody.runFrom.concat(' 00:00'));
                let runToDate = new Date(requestBody.runTo.concat(' 23:59'));
                requestBody.runFrom = format(
                    new Date(addMinutes(runFromDate, runFromDate.getTimezoneOffset())),
                    'yyyy/MM/dd HH:mm'
                );
                requestBody.runTo = format(
                    new Date(addMinutes(runToDate, runToDate.getTimezoneOffset())),
                    'yyyy/MM/dd HH:mm'
                );
            }
            const res = await http.post(`/story/fetchHistory`, requestBody);
            return res && res.data ? res.data : {};
        } catch (err) {
            return {};
        }
    }

    async function getReportFromAPI(requestBody) {
        if (requestBody) {
            const response = await http.post(`/report/download`, requestBody, {responseType: 'blob'});
            if (response?.data?.type !== 'application/json') {
                let blob = new Blob([response.data], {type: 'application/zip'});
                return [blob, response.headers['zipname'] == null ? 'report' : response.headers['zipname']];
            } else {
                return {status: 'Unable to process your request'};
            }
        }
    }


    async function updateMe(userData: UserUpdate): Promise<UserUpdate> {
        const updatedUserValues: UserUpdate = await httpPatch('/me', {
            ...userData,
        });
        await refreshUser(updatedUserValues);
        return updatedUserValues;
    }

    async function updateUserActions(userData) {
        return httpPatch('/me/properties', {
            ...userData,
        });
    }

    async function updateMySettings(settings) {
        return httpPatch('/me/settings', settings);
    }

    async function getInvitationDetails(invitationId) {
        return httpGet(`/company/invitation/${invitationId}`);
    }

    async function getOffer(id) {
        return httpGet(`/offers/${id}`);
    }

    async function getReferral(id) {
        return httpGet(`/referrals/${id}`);
    }

    async function acceptOffer(referralId) {
        return httpPost(`/offers.accept`, {
            referralId,
        });
    }

    async function signUpWithReferral(referralId, signUpInfo) {
        return httpPost(`/offers.sign_up`, {
            referralId,
            ...signUpInfo,
        });
    }

    async function getExtractRunCount() {
        return httpGet(`/data_on_demand/extract_count`);
    }

    async function getStoryInsights(requestBody) {
        dpAbortController = new AbortController();
        return httpPost(`/story/storyInsightValidator`, requestBody, {
            signal: dpAbortController.signal,
        });
    }

    async function fetchReportTileInfo(actionId) {
        return httpGet(`/report/tileInfo`, {
            params: {
                actionId,
            },
        });
    }

    async function checkReportValid(requestBody) {
        return httpPost(`/report/checkData`, requestBody);
    }

    async function checkCPSReportValid(requestBody) {
        return httpPost(`/report/checkPanelReleasability`, requestBody);
    }

    /**
     *
     * @param {*} requestBody
     * @returns
     */
    async function saveDODSelections(requestBody) {
        return httpPost(`/my/dimSelections`, requestBody);
    }

    async function checkPriorApprovalMarkets(requestBody) {
        return httpPost(`/market/isPriorApprovedMarkets`, requestBody);
    }

    /**
     *
     * @param {*} type
     * @returns
     */
    async function getSavedDODSelections(type) {
        return httpGet(`/my/dimSelections/${type}`);
    }

    async function deleteSavedDODSelections(id) {
        return httpDelete(`/my/dimSelections/${id}`);
    }

    /** Return Data On Demand Report Schedule List in array */
    async function getScheduledExtracts(signal?: AbortSignal | undefined) {
        try {
          return httpGet(
              '/scheduled_extracts',
              {
                  signal: signal,
              }
          );
        } catch (err) {
            throw err;
        }
    }

    /** Return Data On Demand Excel Template Array */
    async function getExtractTemplates() {
        return await httpGet(`/extract_templates`);
    }

    async function getUploadTemplateUrl(extractId, strTemplateName) {
        return await httpPost(`/data_on_demand/template_upload`, {
            extractId,
            templateName: strTemplateName,
        });
    }

    async function uploadExcelTemplate(fileData, bucketURL, onUploadProgress) {
        return await httpPut(bucketURL, fileData, {
            onUploadProgress,
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
    }

    async function confirmTemplateUpload(templateId, action) {
        return await httpPost(`/data_on_demand/confirm_template_upload`, {
            templateId,
            action,
        });
    }

    async function getTemplateDownloadLink(templateId) {
        return await httpGet(`/data_on_demand/template_download/${templateId}`);
    }

    async function getTemplateDownloadLinks(templateIds) {
        return await httpPost('/data_on_demand/template_download', { templateIds });
    }

    async function deleteTemplate(templateId) {
        return await httpDelete(`/data_on_demand/template_delete/${templateId}`);
    }

    async function deleteTemplates(templateIds) {
        return await httpDelete('/data_on_demand/template_delete', { data: {templateIds} });
    }

    async function scheduleExtractReport({
                                             reportName,
                                             reporSize,
                                             ExtractLayout,
                                             ExtractSelection,
                                             id,
                                             jobId,
                                             scheduleInfo,
                                             url,
                                         }) {
        return await httpPost(url, {
            reportName,
            reporSize,
            ExtractLayout,
            ExtractSelection,
            id,
            jobId,
            scheduleInfo,
        });
    }

    async function updateDoDSelection(id, updates) {
        return httpPatch(`/my/dimSelections/${id}`, {
            ...updates,
        });
    }

    async function checkIsCategoryMarkets(requestBody) {
        return httpPost(`/market/isCategoryMarkets`, requestBody);
    }

    async function getFullAccount(): Promise<FullAccount> {
        const {maxDataDates, ...me} = await httpGet('/me');

        return {
            ...me,
            maxDataDates: toMaxDataDates(maxDataDates),
        };
    }

    async function updateResetPasswordFlag(): Promise<void> {
        return httpPut(`/me/reset_password_flag`);

    }

    async function saveTcsSelection(hasAcceptedTcs: boolean): Promise<void> {
        return httpPut(`/me/save_tcs_selection`, {hasAcceptedTcs});
    }

    async function getMyCategories(): Promise<string[]> {
        return httpGet('/my/company/category_names');
    }

    const TOTAL_US = 'Total US';
    const BY_CHANNEL_FMCG = 'By Channel FMCG';
    const BY_CHANNEL_SPECIALITY = 'By Channel Speciality';
    const BY_GEOGRAPHY = 'By Geography';
    const PANEL_TOTAL_US_MARKETS = 'Panel Total US Markets';
    const PANEL_BY_CHANNEL = 'Panel By Channel';
    const SELECT_BY_CHANNEL = 'Select By Channel';

    async function getChildMarketNodes({
                                           parent,
                                           datatype,
                                           runType,
                                           categories = [],
                                           includeTotalUSMarkets = false,
                                           includeFmcgRetailers = false,
                                           includeSpecialityRetailers = false,
                                           includeGeographyMarkets = false,
                                           includePanelTotal = false,
                                           includePanelChannel = false,
                                       }: GetChildMarketNodesParams): Promise<MarketNode[]> {
        // todo: create a better version of this api on the server side
        const {markets} = await httpPost('/market/lookup', {
            categories,
            reportType: runType,
            mrktGrp: datatype.toUpperCase(),
            embodyTotalMarkets: includeTotalUSMarkets,
            embodyFmcgRetailers: includeFmcgRetailers,
            embodySpecialityRetailers: includeSpecialityRetailers,
            embodyGeographyMarkets: includeGeographyMarkets,
            embodyPanelTotal: includePanelTotal,
            embodyPanelByChannel: includePanelChannel,
            mrktHier: parent.childRootPath,
            mrktSelType: parent.selectionType,
        });

        return toMarketNodes(markets);
    }

    async function getRootMarketNodes({
                                          datatype,
                                          runType,
                                          categories = [],
                                          includeTotalUSMarkets = false,
                                          includeFmcgRetailers = false,
                                          includeSpecialityRetailers = false,
                                          includeGeographyMarkets = false,
                                          includePanelTotal = false,
                                          includePanelChannel = false,
                                      }: GetMarketNodesParams): Promise<RootMarketNodes> {
        const markets = await httpPost('/market/lookup', {
            categories,
            reportType: runType,
            mrktGrp: datatype.toUpperCase(),
            embodyTotalMarkets: includeTotalUSMarkets,
            embodyFmcgRetailers: includeFmcgRetailers,
            embodySpecialityRetailers: includeSpecialityRetailers,
            embodyGeographyMarkets: includeGeographyMarkets,
            embodyPanelTotal: includePanelTotal,
            embodyPanelByChannel: includePanelChannel,
        });

        return {
            fmcgRetailers: toMarketNodes(markets[SELECT_BY_CHANNEL]?.[BY_CHANNEL_FMCG]),
            specialityRetailers: toMarketNodes(markets[SELECT_BY_CHANNEL]?.[BY_CHANNEL_SPECIALITY]),
            geographies: toMarketNodes(markets[BY_GEOGRAPHY]),
            totalUs: toMarketNodes(markets[TOTAL_US]),
            panelTotal: toMarketNodes(markets[PANEL_TOTAL_US_MARKETS]),
            panelChannels: toMarketNodes(markets[PANEL_BY_CHANNEL]),
        };
    }

    async function searchMarkets({
                                     searchText,
                                     datatype,
                                     runType = 'subscription',
                                     categories = [],
                                     subMarketType,
                                     includeTotalUSMarkets = false,
                                     includeFmcgRetailers = false,
                                     includeSpecialityRetailers = false,
                                     includeGeographyMarkets = false,
                                     includePanelTotal = false,
                                     includePanelChannel = false,
                                 }: SearchMarketsParams): Promise<MarketNode[]> {
        const rawMarkets = await httpPost('/market/search', {
            searchTerm: searchText,
            marketType: datatype.toUpperCase(),
            embodyTotalMarkets: includeTotalUSMarkets,
            embodyFmcgRetailers: includeFmcgRetailers,
            embodySpecialityRetailers: includeSpecialityRetailers,
            embodyGeographyMarkets: includeGeographyMarkets,
            embodyPanelTotal: includePanelTotal,
            embodyPanelByChannel: includePanelChannel,
            reportType: runType,
            categories,
            subMarketType,
        });

        return toMarketNodes(rawMarkets);
    }

    async function getAccessibleMarkets({
                                            datatype,
                                            runType,
                                            categories = [],
                                            includeTotalUSMarkets = false,
                                            includeFmcgRetailers = false,
                                            includeSpecialityRetailers = false,
                                            includeGeographyMarkets = false,
                                            includePanelTotal = false,
                                            includePanelChannel = false,
                                        }: GetMarketNodesParams): Promise<MarketNode[]> {
        const markets = await httpPost(`/market/accessibleMarkets`, {
            categories,
            reportType: runType,
            marketType: datatype.toUpperCase(),
            embodyTotalMarkets: includeTotalUSMarkets,
            embodyFmcgRetailers: includeFmcgRetailers,
            embodySpecialityRetailers: includeSpecialityRetailers,
            embodyGeographyMarkets: includeGeographyMarkets,
            embodyPanelTotal: includePanelTotal,
            embodyPanelByChannel: includePanelChannel,
        });

        return toMarketNodes(markets);
    }

    function toMarketNodes(rawMarkets?: any[]): MarketNode[] {
        const markets: MarketNode[][] =
            rawMarkets?.map(
                ({
                     causal_block,
                     comparative_mkt_disp_name,
                     comparative_mrkt_key,
                     has_children,
                     is_mrkt,
                     master_company,
                     mrkt_entitlements,
                     mrkt_key,
                     mrkt_sel_typ,
                     mrkt_type,
                     name,
                     parent_folder_path,
                     raw_mrkt_name,
                     rm_channel_mrkt_disp_name,
                     rm_channel_mrkt_key,
                     rm_xaoc_mrkt_disp_name,
                     rm_xaoc_mrkt_key,
                     // is_prior_approval,
                     // is_premium,
                     is_focus_market,
                     is_suppressed,
                     root_folder_name,
                     sub_mrkt_type,
                     lvl,
                     is_releasable_mrkt,
                     parent_company
                 }) => {
                    let remainingMarketNames: string[] = [];

                    if (rm_channel_mrkt_disp_name) {
                        remainingMarketNames.push(rm_channel_mrkt_disp_name);
                    }
                    if (rm_xaoc_mrkt_disp_name) {
                        remainingMarketNames.push(rm_xaoc_mrkt_disp_name);
                    }
                    if (!remainingMarketNames.length && comparative_mrkt_key) {
                        remainingMarketNames.push(comparative_mkt_disp_name);
                    }

                    return [
                        {
                            type: 'market',
                            key: mrkt_key,
                            name: name,
                            path: toMarketPath(raw_mrkt_name, parent_folder_path),
                            childRootPath: parent_folder_path ? `${parent_folder_path}\\${raw_mrkt_name}` : raw_mrkt_name,
                            hasChildren: has_children === 'Y',
                            selectable: Boolean(is_mrkt === 'Y'),
                            selectionType: mrkt_sel_typ,
                            marketGroup: mrkt_type,
                            hasLimitedData: causal_block === 'Y',
                           // isReleasableMrkt: is_releasable_mrkt,
                            hasApproval: mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL),
                            hasNoApproval: mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL),
                            hasPartialApproval: mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), //this entity is not used in Reports section today, it is for DOD though
                            purchased: mrkt_entitlements?.includes(MrktEntitlementType.REV_SHARE),
                            unPurchased: mrkt_entitlements?.includes(MrktEntitlementType.NOT_REV_SHARE),
                            categoriesNotCovered: mrkt_entitlements?.includes(MrktEntitlementType.CATEGORY_NOT_COVERED),
                            isRemaining: false,
                            remainingMarketNames,
                            masterCompany: master_company,
                            comparativeMarketKey: comparative_mrkt_key,
                            parentCompany: parent_company
                        } as MarketNode,
                        {
                            type: 'remaining_channel',
                            path: toMarketPath(rm_channel_mrkt_disp_name, parent_folder_path),
                            key: rm_channel_mrkt_key,
                            name: rm_channel_mrkt_disp_name,
                            hasChildren: false,
                            selectable: true,
                            selectionType: mrkt_sel_typ,
                            marketGroup: mrkt_type,
                          //  isReleasableMrkt: is_releasable_mrkt,
                            hasApproval: mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL),
                            hasNoApproval: mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL),
                            hasPartialApproval: mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), //this entity is not used in Reports section today, it is for DOD though
                            purchased: mrkt_entitlements?.includes(MrktEntitlementType.REV_SHARE),
                            unPurchased: mrkt_entitlements?.includes(MrktEntitlementType.NOT_REV_SHARE),
                            categoriesNotCovered: mrkt_entitlements?.includes(MrktEntitlementType.CATEGORY_NOT_COVERED),
                            isRemaining: true,
                            remainingMarketParentKey: mrkt_key,
                            masterCompany: master_company,
                            parentCompany: parent_company
                        } as MarketNode,
                        {
                            type: 'remaining_market',
                            path: toMarketPath(rm_xaoc_mrkt_disp_name, parent_folder_path),
                            key: rm_xaoc_mrkt_key,
                            name: rm_xaoc_mrkt_disp_name,
                            hasChildren: false,
                            selectable: true,
                            selectionType: mrkt_sel_typ,
                            marketGroup: mrkt_type,
                         //   isReleasableMrkt: is_releasable_mrkt,
                            hasApproval: mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL),
                            hasNoApproval: mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL),
                            hasPartialApproval: mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL), //this entity is not used in Reports section today, it is for DOD though
                            purchased: mrkt_entitlements?.includes(MrktEntitlementType.REV_SHARE),
                            unPurchased: mrkt_entitlements?.includes(MrktEntitlementType.NOT_REV_SHARE),
                            categoriesNotCovered: mrkt_entitlements?.includes(MrktEntitlementType.CATEGORY_NOT_COVERED),
                            isRemaining: true,
                            remainingMarketParentKey: mrkt_key,
                            masterCompany: master_company,
                            parentCompany: parent_company
                        } as MarketNode,
                    ];
                }
            ) ?? [];

        return markets.flat().filter(({key, isRemaining}) => Boolean(key) || !isRemaining);
    }

    async function getMarketMaps(marketMapType: string | 'retailer'): Promise<MarketMapResponse> {
        const usage = await httpGet('/marketMaps?marketMapType=' + marketMapType);
        infinitizeUsage(usage);
        return usage;
    }

    /**
     * Converts a name and parent path into a normalized string.
     * This might not even be necessary.  Just using the path + name might be good enough
     * @param name
     * @param parentPath
     */
    function toMarketPath(name: string | null, parentPath?: string): string | void {
        if (!name) return;

        return `${parentPath ?? ''}\\${name}`.replace(/[^\w\d\\]+/g, '_').toLowerCase();
    }

    async function updateReportlet(id: number, reqBody: UpdateReportletReqBody) {
        try {
            await httpPut(`/reportlet/preset/update/${id}`, reqBody);
        } catch (error) {
            console.log(error);
        }
    }

    async function createReportlet(reqBody: UpdateReportletReqBody) {
        try {
            await httpPost(`/reportlet/preset`, reqBody);
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchReportlet(): Promise<ReportletDetails> {
        const res = await httpGet(`reportlet/preset/findByUser`);
        return res;
    }

    async function resetReportlet(id: number) {
        try {
            await httpGet(`reportlet/preset/syncWithDefault/${id}`);
        } catch (error) {
            console.log(error);
        }
    }

    async function downloadMarketMapsFolder(folderId: number, folderName?: string) {
        const data = await httpGet(`/marketMaps/folder/${folderId}/download`, {responseType: 'blob'});

        if (data.type !== 'application/json') {
            const blob = new Blob([data], {type: 'application/zip'});
            // @ts-ignore
            downloadMarketMapResources(blob, undefined, folderName);
        } else {
            return {status: 'Unable to process your request'};
        }
    }

    function downloadMarketMapResources(blob: Blob, fileType: keyof typeof MIME_TYPES = 'zip', fileName: string) {
        var csvURL = window.URL.createObjectURL(blob);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        if (fileType !== 'zip') {
            tempLink.download = fileName;
        } else {
            tempLink.setAttribute('download', `market-maps_${fileName}.zip`);
        }
        tempLink.click();
        return;
    }

    async function updateTenantCompanyBrandInfo(tenantCompanyBrandInfo: {
        brands?: string[],
        brandNotRetailingYet?: boolean
    }): Promise<{
        brands: string[];
        brandNotRetailingYet: boolean;
    }> {
        return httpPatch(`/company/preferences`, {
            tenantCompanyBrandInfo,
        });
    };

    async function updateTenantBrands(brands: string[]): Promise<{ brands: string[] }> {
        return httpPut('/me/tenant/brands', {brands});
    }

    async function updateTenantCompanySettings<T extends any>(key: string, value: T): Promise<{
        settings: Record<string, T>
    }> {
        return httpPatch('/me/company/settings', {key, value});
    }

    async function updateTenantCompanyMetadata<T extends any>(key: string, value: T): Promise<{
        metadata: Record<string, T>
    }> {
        return httpPatch('/me/company/metadata', {key, value});
    }

    async function getCompanyBrandInfo(): Promise<{ brands?: string[]; brandNotRetailingYet?: boolean; }> {
        return httpGet(`/me/tenant/brandInfo`);
    }

    async function downloadMarketMapFile(marketMap: MarketMap) {
        if (marketMap.fileId) {
            const fileType: keyof typeof MIME_TYPES = ((file) => {
                if (!file) return 'docx';
                switch (true) {
                    case file.endsWith('.pptx'):
                        return 'pptx';
                    case file.endsWith('.ppt'):
                        return 'ppt';
                    case file.endsWith('.xlsx'):
                        return 'xlsx';
                    case file.endsWith('.xlsb'):
                        return 'xlsb';
                    case file.endsWith('.xls'):
                        return 'xls';
                    case file.endsWith('.pdf'):
                        return 'pdf';
                    case file.endsWith('.doc'):
                        return 'doc';
                    case file.endsWith('.zip'):
                        return 'zip';
                    default:
                        return 'docx';
                }
            })(marketMap?.file);

            const response = await http.get(`/marketMaps/file/${marketMap.fileId}/download/${fileType}`, {responseType: 'blob'});
            // Extract filename from header
            const contentDisposition = response.headers['Content-Disposition'];
            let fileName = `${marketMap.fileName!.replace(`.${fileType}`, "")}`;
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/) || '';
                if (fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1];
                }
            }
            const blob = new Blob([response.data], {
                type: MIME_TYPES[fileType]
            });
            // @ts-ignore
            downloadMarketMapResources(blob, fileType, fileName);
            return;
        }
    }

    async function searchMultipleUPCs(searchTextArray: string[]): Promise<UPCDetails> {
        const res = await httpPost(`/product/search/multipleUpc`, {upcs: searchTextArray});
        return res;
    }

    async function getStoryRunById(id: number): Promise<StoryRun> {
        const storyRun = await httpGet(`/story/getStoryRunConfig/${id}`);

        return {
            ...storyRun,
            story: getProductBySku(storyRun.sku),
        };
    }

    async function getUnacceptedQuote(paramObject?: QuoteRequestType): Promise<QuoteDetail> {
        const {data} = await httpGet(`/company/unacceptedQuote`, {
            params: paramObject ?? undefined,
        });
        return data;
    }

    async function acceptQuote(requestBody: QuoteAcceptReqType): Promise<boolean> {
        return httpPut(`/company/quote.accept`, requestBody);
    }

    async function skipQuote(requestBody: QuoteIgnoreReqType): Promise<QuoteResponseType> {
        return httpPut(`/company/quote.ignore`, requestBody);
    }

    async function getAttributeGroupForCategoris(categories: string[], aggregationLevel: string) {
        try {
            const resp = await httpPost(`/product/attributeGroupForCategories`, {
                categories: categories,
                categorySelectionAggregationLevel: aggregationLevel
            });
            return resp?.attributeGroups ?? [];
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    async function getAttributesForAttributeGroup(attributeGroup: string[], aggregationLevel: string) {
        try {
            return await httpPost(`/product/attributesForAttributeGroup  `, {
                attributeGroups: attributeGroup,
                categorySelectionAggregationLevel: aggregationLevel
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    async function findUpcDescriptions(req: ProductValueFilterParams): Promise<UpcDescriptionPair[]> {

        const {results} = await httpPost(`/product/search/upcDescriptionWithFilters`, createPayloadFromParamsForDODFilters(req));
        return results;
    }

    async function findParentCompany(req: ProductValueFilterParams): Promise<string[]> {
        const {results} = await httpPost(`/product/search/parentCompany`, createPayloadFromParamsForDODFilters(req));
        return results;
    }

    async function findManufacturer(req: ProductValueFilterParams): Promise<string[]> {
        const {results} = await httpPost(`/product/search/manufacturer`, createPayloadFromParamsForDODFilters(req));
        return results
    }

    async function findBrands(req: ProductValueFilterParams): Promise<string[]> {
        const {results} = await httpPost(`/product/search/brand`, createPayloadFromParamsForDODFilters(req));
        return results
    }

    function createPayloadFromParamsForDODFilters({
        maxResults = 10000,
        categories,
        subcategories,
        parentCompanies,
        manufacturers,
        brands,
        departments,
        superCategories,
        upcs,
        descriptions,
        searchTerm
    }: ProductValueFilterParams) {
        return {
            search_term: searchTerm,
            categories,
            parent_companies: parentCompanies,
            manufacturers,
            brands,
            max_results: maxResults,
            super_categories: superCategories,
            sub_categories: subcategories,
            departments,
            upcs,
            descriptions
        }
    }

    async function getDodPresets(): Promise<DodPresetMap> {
        return httpGet(`/my/extract_presets`);
    }

    async function createDodPreset(preset: Omit<DodPreset, 'id'>): Promise<DodPreset> {
        return httpPost('/my/extract_presets', preset);
    }

    async function deleteDodPreset(id: number): Promise<void> {
        return httpDelete(`/my/extract_presets/${id}`);
    }

    async function getDodPreset(id: number): Promise<DodPreset> {
        return httpGet(`/my/extract_presets/${id}`);
    }

    async function getDodExcelTemplates(): Promise<DodExcelTemplate[]> {
        return await httpGet(`/extract_templates`);
    }

    async function updatePlatformNotificationPreference(platformNotificationKey: keyof PlatformNotificationEmails, platformNotificationValue: boolean): Promise<PlatformNotificationEmails> {
        return httpPost(`/users/platform_notification_preference`, {platformNotificationKey, platformNotificationValue});
    }

    async function updateDataRefreshNoticationFrequency(dataRefreshNotificationFrequency: DataRefreshNotificationFrequency): Promise<void> {
        return httpPost(`/users/data_refresh_notification_frequency`, {dataRefreshNotificationFrequency});
    }

    async function getStoryRuns() {
        return httpGet('/story/story_runs');
    }

    //  DO NOT ADD ANY NEW CODE BELOW THIS POINT.  IF YOU NEED TO ADD NEW METHODS ADD THEM BEFORE THE COMMENT THAT STARTS
    // THE PRIVATE SECTION
    // IF YOU ADD CODE IN OR AFTER THE PRIVATE SECTION THERE WILL BE CONSEQUENCES

    return {
        findUpcDescriptions,
        findParentCompany,
        findManufacturer,
        findBrands,
        deleteDodPreset,
        createDodPreset,
        getDodPresets,
        getDodPreset,
        getDodExcelTemplates,
        updateDataRefreshNoticationFrequency,
        updatePlatformNotificationPreference,
        lookup,
        getCompanyReportRuns,
        saveFavorites,
        saveNotes,
        createSubscriptionReportRun,
        getSimulatorDatasetValues,
        getReportRunLink,
        validateRunConfig,
        createAdHicReportRun,
        getSubcategoriesForCategories,
        getReportRunById,
        getCreditInfoById,
        getReportRunConfigById,
        getMyReportlets,
        getReportById,
        getOmniDates,
        homePageTiles,
        homePageTileById,
        productTourDataFetch,
        getMyNotifications,
        updateNotification,
        search,
        findRetailProducts,
        getReferenceData,
        getHierarchyData,
        getDemographicValues,
        getMarketKeyFromMarkets,
        findCompanyByDomain,
        getReportLink,
        deleteProductUsingActionId,
        getReportLinkByName,
        getCustomCharReportLinkById,
        getPPGReportLinkById,
        getDrillDownReportLink,
        getScorecardReport,
        getCustomStartDate,
        getMaxDataDates,
        getLatestMaxDataDate,
        getMaxDataDate,
        getCreditUsage,
        getSubscriptionActionCount,
        getMySubscription,
        getMyDownloadableProducts,
        getMyLegacySubscription,
        getMySubscriptionUsage,
        getMyCompanySubscription,
        getMyCompanySubscriptionUsage,
        getMyProducts,
        isScorecardEnabled,
        getDatasetById,
        getMyProductsByType,
        getUserFavorite,
        createUserFavorite,
        deleteUserFavorite,
        checkUpcsInCategories,
        getMyPPGs,
        getPPGById,
        getPpgGroupNames,
        createPPG,
        updatePPG,
        deletePPG,
        getCustomChars,
        getCustomChar,
        createCustomChar,
        renameCustomChar,
        updateCustomChar,
        deleteCustomChar,
        getReportEmbedFromActionHistory,
        getActionHistory,
        getUserAlertHistory,
        getCompanyReportHistory,
        marketLookup,
        dodMarketLookup,
        isPriorApprovalMarket,
        convertCatKeys,
        marketSearch,
        dodMarketSearch,
        marketAncestry,
        getSignupProperties,
        productAncestry,
        brandSearch,
        findBrandsForCategories,
        manufacturerForCategoriesSearch,
        manufacturerSearch,
        parentCompanySearch,
        submitUpcCodingRequest,
        categorySearch,
        fetchBrandList,
        getCatalogByItemName,
        getCategoriesForBrands,
        getCategoryByBrand,
        getCategoriesByBrands,
        getCategoriesByManufacturers,
        getCategoriesWithinBrand,
        searchBrandForProductSet,
        getAdvancedMarketFilters,
        getMarketsByAdvancedFilters,
        getMarketsByAdvancedFiltersForDodSelections,
        findBrandsByCategories,
        searchBrandForFocusProduct,
        findOmniBrandsInCategories,
        findOmniBrandsFamilyInCategories,
        findOmniBrandLowForCategories,
        searchManufacturerForFocusProduct,
        findManufacturersInCategories,
        getProductCharacteristics,
        getProductChars,
        getCharacteristicsForCategories,
        getCharacteristicValuesForCategories,
        getCharacteristicValues,
        getUPCForCategories,
        getCompanyPreferences,
        createTenantCompanyProductConfig,
        updateTenantCompanyBrandInfo,
        updateUser,
        getAdmins,
        getTeamMembers,
        updateTeamMember,
        getTeams,
        createTeam,
        updateTeam,
        deleteTeam,
        deleteCompanyUser,
        inviteUser,
        inviteUsers,
        addExternalUserToCompany,
        getCompanyMultiTenancyForUserEmail,
        removeInvitation,
        removeUser,
        approveUser,
        getUserPreferences,
        saveUserRunConfig,
        saveUserDefaultRunConfig,
        setCompanyAlerts,
        getCompanyAlerts,
        getSubscription,
        sendResetPasswordLink,
        acceptInvitation,
        findCategoriesForBrand,
        getAllProductsByType,
        createAlerts,
        updateAlerts,
        getConfiguredAlerts,
        deleteAlert,
        getTopTenMarketsForBrand,
        getTopCategoriesForBrands,
        getTopTenMarketsForCategory,
        getTopTenBrandsForCategory,
        PanelReleasabilityForCategory,
        PanelReleasabilityForBrand,
        getPriorApprovalStatus,
        getPriorApprovalRetailers,
        calculateScores,
        calculateScoresCount,
        overallScoreTrends,
        appendAudit,
        getRemainingMarkets,
        getPurchasedThemes,
        getCategoriesForTheme,
        getCharsForThemeAndCategories,
        getCPSPanelReleasability,
        getFactConfig,
        getExtracts,
        getExtractById,
        getScheduledExtractById,
        getExtractSignedUrl,
        getExtractSignedUrls,
        getExtractDataPoints,
        deleteExtractReport,
        deleteMultipleExtractReports,
        runExtractReport,
        runExtractLayoutChange,
        retailerHierarchies,
        getCategoryHierarchyTree,
        getSubscriptionSpecificMarkets,
        getOmniMarkets,
        buildStories,
        getMyStorySubscription,
        getMyStoryObjectiveList,
        downloadStory,
        getParentCompanySearch,
        getManufacturerSearch,
        getBrandsSearch,
        getUpcDescriptionSearch,
        getProductDescription,
        deleteStoryHistory,
        getStoryNames,
        marketSearchByMrktType,
        marketSearchByAccount,
        getMarketDispNames,
        getCategoryCount,
        getHandlebarsTemplate,
        getEntitledMarketTypes,
        getStoriesHistory,
        getReportFromAPI,
        updateMe,
        updateUserActions,
        saveFreeUserDefaultRunConfig,
        updateMySettings,
        getInvitationDetails,
        getOffer,
        getReferral,
        acceptOffer,
        signUpWithReferral,
        getExtractRunCount,
        getStoryInsights,
        fetchReportTileInfo,
        checkReportValid,
        checkCPSReportValid,
        saveDODSelections,
        checkPriorApprovalMarkets,
        getSavedDODSelections,
        deleteSavedDODSelections,
        getScheduledExtracts,
        getExtractTemplates,
        getUploadTemplateUrl,
        uploadExcelTemplate,
        confirmTemplateUpload,
        getTemplateDownloadLink,
        getTemplateDownloadLinks,
        deleteTemplate,
        deleteTemplates,
        scheduleExtractReport,
        updateDoDSelection,
        checkIsCategoryMarkets,
        getFullAccount,
        updateResetPasswordFlag,
        saveTcsSelection,
        getDefaultRunConfig,
        getMyCategories,
        getChildMarketNodes,
        getRootMarketNodes,
        searchMarkets,
        getAccessibleMarkets,
        getMarketMaps,
        updateReportlet,
        createReportlet,
        fetchReportlet,
        resetReportlet,
        downloadMarketMapsFolder,
        downloadMarketMapFile,
        searchMultipleUPCs,
        getStoryRunById,
        getUnacceptedQuote,
        acceptQuote,
        skipQuote,
        getAttributeGroupForCategoris,
        getAttributesForAttributeGroup,
        toReportRun,
        entityHasType,
        getBusinessQuestionValues,
        getRolesValues,
        getProductInformation,
        buildScoresCriteria,
        buildCriteria,
        infinitizeUsage,
        toMarketNodes,
        toMarketPath,
        dpAbortCtrler: dpAbortController,
        clearApiOboUser,
        setApiOboUser,
        setTenantApiHeader,
        getAvailableTenants,
        updateCategories,
        updateOmniCategories,
        getCustomCharacteristics,
        estimateExtractDataPoints,
        createExtract,
        createExtractSchedule,
        getCustomCharWithNASegment,
        getChrCodeDisplayMap,
        updateTenantCompanySettings,
        updateTenantBrands,
        getCompanyBrandInfo,
        updateTenantCompanyMetadata,
        markTipViewed,
        getRunConfigOptionsByCreditId,
        getBrandScoreOpportunities,
        savePersonas,
        getStoryRuns
    }

};
// ******************************************************************************************************************
// PRIVATE UTILITY FUNCTIONS DO NOT ADD ANY NEW CODE BELOW THIS POINT
// ******************************************************************************************************************

/**
 * A wrapper around axios.httpGet that adds our custom error handling
 * @param url
 * @param config
 * @returns {Promise<any>}
 */
async function httpGet(url: string, config?: AxiosRequestConfig) {
    try {
        const {data} = await http.get(url, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.httpPost that adds our custom error handling
 * @param url
 * @param body
 * @param config
 * @returns {Promise<any>}
 */
async function httpPost(url: string, body: any, config?: AxiosRequestConfig) {
    try {
        const {data} = await http.post(url, body, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.httpPost that adds our custom error handling and config
 * @param url
 * @param payload{any}
 * @param config{AxiosRequestConfig}
 * @returns {Promise<any>}
 */
async function httpPostConfig(url: string, payload: any, config?: AxiosRequestConfig) {
    try {
        const {data} = await http.post(url, {...payload}, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.httpPatch that adds our custom error handling
 * @param url
 * @param body
 * @param config
 * @returns {Promise<any>}
 */
async function httpPatch(url: string, body: any, config?: AxiosRequestConfig) {
    try {
        const {data} = await http.patch(url, body, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.httpPatch that adds our custom error handling
 * @param url
 * @param body
 * @param config
 * @returns {Promise<any>}
 */
async function httpPut(url: string, body?: any, config?: AxiosRequestConfig) {
    try {
        const {data} = await http.put(url, body, config);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 * A wrapper around axios.delete that adds our custom error handling
 * @param url
 * @param options
 * @returns {Promise<any>}
 */
async function httpDelete(url, ...options) {
    try {
        const {data} = await http.delete(url, ...options);
        return data;
    } catch (err) {
        handleError(err);
    }
}

/**
 *
 * common error handle that should be used by all functions
 * @param err
 */
function handleError(err) {

    if (err?.response) {
        const {status, data} = err.response;
        throw {
            status,
            code: data?.code,
            id: data?.id,
        };
    }

    // todo: add common error handling and event triggering for connectivity issues
    if(err?.isAxiosError || err?.code === 'ERR_NETWORK') {
        throw {
            status: -1,
            code: 'network_error',
        };
    }

    return err; 
}
