import './DodProductFilterValuePicker.scss';
import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import {ByzzerChangeEventHandler} from '@byzzer/ui-components';
import {useUser} from '@/contexts/UserContext';
import {
    alertSelectionLimitExceed,
    filterHasExplicitValues,
    filterHasValues,
    filterValuesToStrings,
} from '@/components/DodConfigEditor/common/utils';
import {DodFilterType, DodProductFilterType} from '@/components/DodConfigEditor/types';
import {useCategoryService} from '@/services/category.service';
import {
    DodFilterValuePicker,
    DodFilterValuePickerRef,
    DodValueOption,
} from '@/components/DodConfigEditor/common/DodFilterValuePicker';
import {DodFilters} from '@/types/DodRun';
import {isAsyncFunction} from '@/utils';
import {useTenantApi} from '@/hooks';
import {alert} from '@/components/form';

const baseClassName = 'dod-product-filter-value-picker';

export type DodProductFilterValuePickerProps = {
    name?: string;
    className?: string;
    filterType: DodFilterType<DodProductFilterType>;
    filters: DodFilters;
    value?: string[] | 'all';
    onChange?: ByzzerChangeEventHandler<string[]>;
    onApply?: ByzzerChangeEventHandler<string[]>;
    actions?: ActionConfig[];
    pickerRef?: React.Ref<DodFilterValuePickerRef>;
    includeActions?: boolean;
    filterText?: string;
    limit?: number;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

type FilterValueConfig = {
    getOptions(values: DodFilters, data: any): Promise<DodValueOption[]> | DodValueOption[];
    valueToStrings?(value: any): string[] | 'all';
    stringsToValue?(strings: string[] | 'all'): any;
    filterValues?(searchTerm: string): Promise<DodValueOption[]> | DodValueOption[];
};

function defaultValueToStrings(value: any) {
    return value;
}

function defaultStringsToValue(strings: string[] | 'all'): string[] | 'all' {
    return strings;
}

export function DodProductFilterValuePicker({
    name,
    className,
    filterType,
    filters,
    value,
    onChange,
    onApply,
    limit,
    ...props
}: DodProductFilterValuePickerProps) {
    const {
        findBrands,
        findManufacturer,
        findParentCompany,
        findUpcDescriptions,
        getCharacteristicValuesForCategories,
        getCustomCharWithNASegment,
        getUpcDescriptionSearch,
        getManufacturerSearch,
        getBrandsSearch,
        getParentCompanySearch,
        getPPGById
    } = useTenantApi();
    const { categories: userCategories } = useUser();
    const {
        getSuperCategoriesForDepartments,
        getCategoriesForDepartments,
        getSubCategoriesForDepartments,
        getCategoriesForSuperCategories,
        getSubCategoriesForSuperCategories,
        getSubCategoriesForCategories,
        getSuperCategoriesForCategories,
        getDepartsForCategories,
        getCategoriesBasedOnFilter
    } = useCategoryService();
    const [options, setOptions] = useState<DodValueOption[]>([]);
    const filterValueConfigs = useMemo<Record<DodProductFilterType, FilterValueConfig>>(
        () => ({
            categories: {
                getOptions(filters) {
                    let categories: string[] = [];
                    if (filterHasExplicitValues(filters.superCategories)) {
                        categories = getCategoriesForSuperCategories(
                            filterValuesToStrings(filters.superCategories)
                        ).filter((category) => userCategories?.includes(category));
                    } else if (filterHasExplicitValues(filters.departments)) {
                        categories = getCategoriesForDepartments(
                            filterValuesToStrings(filters.departments)
                        ).filter((category) => userCategories?.includes(category));
                    } else {
                        categories = userCategories;
                    }
                    return categories.map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            subcategories: {
                getOptions(filters) {
                    let subcategories: string[] = [];
                    if (filterHasExplicitValues(filters.categories)) {
                        subcategories = getSubCategoriesForCategories(
                            filterValuesToStrings(filters.categories, userCategories)
                        );
                    } else if (filterHasExplicitValues(filters.superCategories)) {
                        subcategories = getSubCategoriesForSuperCategories(
                            filterValuesToStrings(filters.superCategories), true
                        );
                    } else if (filterHasExplicitValues(filters.departments)) {
                        subcategories = getSubCategoriesForDepartments(filterValuesToStrings(filters.departments), true);
                    } else {
                        subcategories = getSubCategoriesForCategories(userCategories);
                    }
                    return subcategories.map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            superCategories: {
                getOptions(filters) {
                    let superCategories: string[] = [];
                    const userSuperCategories = getSuperCategoriesForCategories(userCategories);
                    if (filterHasExplicitValues(filters.departments)) {
                        superCategories =
                            getSuperCategoriesForDepartments(
                                filterValuesToStrings(filters.departments)
                            )?.filter((item) => userSuperCategories.includes(item)) || [];
                    } else {
                        superCategories = userSuperCategories;
                    }
                    return superCategories.map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            departments: {
                getOptions(filters) {
                    return getDepartsForCategories(userCategories).map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            upcs: {
                async getOptions(filter) {
                    const upcs = await findUpcDescriptions({
                        categories: getCategoriesBasedOnFilter(filters, userCategories),
                        subcategories: filterValuesToStrings(filters.subcategories),
                        parentCompanies: filterValuesToStrings(filters.parentCompanies),
                        manufacturers: filterValuesToStrings(filters.manufacturers),
                        brands: filterValuesToStrings(filters.brands),
                    });
                    return upcs.map(({ upc, description }) => ({
                        text: `${upc} ${description}`,
                        value: `${upc} ${description}`,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const upcs = await getUpcDescriptionSearch({
                            max_results: 10000,
                            categories: getCategoriesBasedOnFilter(filters, userCategories),
                            sub_categories: filterValuesToStrings(filters.subcategories),
                            parent_companies: filterValuesToStrings(filters.parentCompanies),
                            manufacturers: filterValuesToStrings(filters.manufacturers),
                            brands: filterValuesToStrings(filters.brands),
                            search_term: searchTerm ? searchTerm : undefined,
                        });
                        return upcs.results.map(({ upc, description }) => ({
                            text: `${upc} ${description}`,
                            value: `${upc} ${description}`,
                        }));
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            brands: {
                async getOptions() {

                    const brandsData = await findBrands({
                        categories: getCategoriesBasedOnFilter(filters, userCategories),
                        subcategories: filterValuesToStrings(filters.subcategories),
                        parentCompanies: filterValuesToStrings(filters.parentCompanies),
                        manufacturers: filterValuesToStrings(filters.manufacturers),
                        upcs: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                        descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                    });
                    return brandsData.map((brand) => ({
                        text: brand,
                        value: brand,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const brandsData = await getBrandsSearch({
                            max_results: 10000,
                            categories: getCategoriesBasedOnFilter(filters, userCategories),
                            sub_categories: filterValuesToStrings(filters.subcategories),
                            parent_companies: filterValuesToStrings(filters.parentCompanies),
                            manufacturers: filterValuesToStrings(filters.manufacturers),
                            upcs: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                            descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                            search_term: searchTerm,
                        });
                        return brandsData.results.map((brand) => ({
                            text: brand,
                            value: brand,
                        }));
                    } catch (e) {
                        return [];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            manufacturers: {
                async getOptions() {
                    const manufacturerData = await findManufacturer({
                        categories: getCategoriesBasedOnFilter(filters, userCategories),
                        subcategories: filterValuesToStrings(filters.subcategories),
                        parentCompanies: filterValuesToStrings(filters.parentCompanies),
                        manufacturers: filterValuesToStrings(filters.manufacturers),
                        brands: filterValuesToStrings(filters.brands),
                        upcs: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                        descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                    });
                    return manufacturerData.map((manufacturer) => ({
                        text: manufacturer,
                        value: manufacturer,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const manufacturerData = await getManufacturerSearch({
                            max_results: 10000,
                            departments: filterValuesToStrings(filters.departments),
                            super_categories: filterValuesToStrings(filters.superCategories),
                            categories: getCategoriesBasedOnFilter(filters, userCategories),
                            sub_categories: filterValuesToStrings(filters.subcategories),
                            parent_companies: filterValuesToStrings(filters.parentCompanies),
                            upcs: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                            descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                            brands: filterValuesToStrings(filters.brands),
                            search_term: searchTerm,
                        });
                        return manufacturerData.results.map((manufacturer) => ({
                            text: manufacturer,
                            value: manufacturer,
                        }));
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            parentCompanies: {
                async getOptions() {
                    const parentCompanyData = await findParentCompany({
                        categories: getCategoriesBasedOnFilter(filters, userCategories),
                        subcategories: filterValuesToStrings(filters.subcategories),
                        parentCompanies: filterValuesToStrings(filters.parentCompanies),
                        manufacturers: filterValuesToStrings(filters.manufacturers),
                        brands: filterValuesToStrings(filters.brands),
                        upcs: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                        descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                    });
                    return parentCompanyData.map((parentCompany) => ({
                        text: parentCompany,
                        value: parentCompany,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const upcs = await getParentCompanySearch({
                            max_results: 10000,
                            departments: filterValuesToStrings(filters.departments),
                            super_categories: filterValuesToStrings(filters.superCategories),
                            categories: getCategoriesBasedOnFilter(filters, userCategories),
                            sub_categories: filterValuesToStrings(filters.subcategories),
                            upcs: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                            descriptions: filterHasValues(filters.upcs)
                                ? true
                                : !filterHasExplicitValues(filters.productDescriptions)
                                ? []
                                : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                            manufacturers: filterValuesToStrings(filters.manufacturers),
                            brands: filterValuesToStrings(filters.brands),
                            search_term: searchTerm,
                        });
                        return upcs.results.map((parentCompany) => ({
                            text: parentCompany,
                            value: parentCompany,
                        }));
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            productDescriptions: {
                async getOptions() {
                    const upcs = await findUpcDescriptions({
                        categories: getCategoriesBasedOnFilter(filters, userCategories),
                        subcategories: filterValuesToStrings(filters.subcategories),
                        parentCompanies: filterValuesToStrings(filters.parentCompanies),
                        manufacturers: filterValuesToStrings(filters.manufacturers),
                        brands: filterValuesToStrings(filters.brands),
                    });
                    return upcs.map(({upc, description}) => ({
                        text: `${upc} ${description}`,
                        value: `${upc} ${description}`,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const upcs = await getUpcDescriptionSearch({
                            max_results: 10000,
                            categories: getCategoriesBasedOnFilter(filters, userCategories),
                            sub_categories: filterValuesToStrings(filters.subcategories),
                            parent_companies: filterValuesToStrings(filters.parentCompanies),
                            manufacturers: filterValuesToStrings(filters.manufacturers),
                            brands: filterValuesToStrings(filters.brands),
                            search_term: searchTerm ? searchTerm : undefined,
                        });
                        return upcs.results.map(({ upc, description }) => ({
                            text: `${upc} ${description}`,
                            value: `${upc} ${description}`,
                        }));
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            characteristics: {
                async getOptions(filters, { id }) {
                    const categories = getCategoriesBasedOnFilter(filters, userCategories);
                    const characteristics = await getCharacteristicValuesForCategories(categories, id);
                    return characteristics.map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            customCharacteristics: {
                async getOptions(filters, { id }) {
                    let customCharsValueOptions = await getCustomCharWithNASegment(id);
                    return customCharsValueOptions.map((customChar) => ({
                        text: customChar,
                        value: customChar,
                    }));
                },
            },
            ppgs: {
                async getOptions(filters, { id }) {
                    const categories = getCategoriesBasedOnFilter(filters, userCategories);
                    let ppgValueOptions = await getPPGById(id);
                    return ppgValueOptions?.groups.map((ppgGroup) => ({
                        text: ppgGroup.groupName,
                        value: ppgGroup.groupName,
                    })) ?? [];
                },
            },
        }),
        [filters]
    );
    const [normalizedValue, setNormalizedValue] = useState<string[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        (async () => {
            const { getOptions } = filterValueConfigs[filterType.type];
            setLoading(isAsyncFunction(getOptions));
            const options = await getOptions(filters, filterType.data);
            setOptions(options ?? []);
            setLoading(false);
        })();
    }, [filterType.type, filterType.data]);

    useEffect(() => {
        const { valueToStrings = defaultValueToStrings } = filterValueConfigs[filterType.type];
        setNormalizedValue(valueToStrings(value));
    }, [value, filterType, filters]);

    function handleChange(e) {
        const { stringsToValue = defaultStringsToValue } = filterValueConfigs[filterType.type];
        onChange?.({
            ...e,
            value: stringsToValue(e.value),
        });
    }

    async function handleApply(e) {
        const { stringsToValue = defaultStringsToValue } = filterValueConfigs[filterType.type];

        if (filterType.type === 'customCharacteristics' && stringsToValue(e.value)?.length) {
            alert({
                title: 'FYI',
                content: <>
                    <p>Including Custom Characteristics will slow down the Data Point counter.</p>
                    <p>If it's calculating for longer than usual, that's why.</p>
                </>
            })
        }

        onApply?.({
            ...e,
            value: stringsToValue(e.value),
        });
    }
    const getLimitExceedFilterTypeName = useMemo(() => {
        switch (filterType.type) {
            case 'categories':
                return 'category(s)';

            case 'subcategories':
                return 'sub-category(s)';

            case 'departments':
                return 'department(s)';

            case 'superCategories':
                return 'super-category(s)';

            default:
                return 'category(s)';
        }
    }, [filterType]);

    return (
        <DodFilterValuePicker
            className={classnames(baseClassName, className)}
            options={options}
            limit={limit}
            loading={loading}
            onLimitExceed={() => alertSelectionLimitExceed(getLimitExceedFilterTypeName, limit)}
            onChange={handleChange}
            onApply={handleApply}
            value={normalizedValue}
            onFilterChange={filterValueConfigs[filterType.type].filterValues}
            dodWizardStep="product"
            {...props}
        />
    );
}

export default DodProductFilterValuePicker;