import { useEffect, useState } from 'react';
import { selectorStates } from '@/config/globalVars';
import { useStoriesContext } from '@/views/story/buildStory/StoriesContext';
import ByzzerMarketSearch from '@/components/MarketSelection/ByzzerMarketSearch/ByzzerMarketSearch';
import { ByzzerInput } from '@/components/form';
import { RunConfigMarket } from '@/types/ReportRun';
import { useMarketService } from '@/services/market.service';
import { MarketPickerRunConfigMarket } from '@/types/StoriesTypes';
import { MarketPicker } from '@/components/MarketPicker';
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo/SelectorLabelInfo';
import {useUser} from "@/contexts/UserContext";

export const StoryMarketSelector = () => {
    const {
        dataSpecificationValues,
        setDataSpecificationValues,
        updatePanelStatus,
        getSelectorConfiguration,
        selectedMarkets,
        setSelectedMarkets,
    } = useStoriesContext();
    const { getCachedMarketNodeByName } = useMarketService();
    let { selectorConfiguration, selectorIndex } = getSelectorConfiguration('market');
    const [requiredMarketGroup, setRequiredMarketGroup] = useState('');
    const [categories, setCategories] = useState(dataSpecificationValues.runConfig.categories);
    const [selectedMaxMarkets, setSelectedMaxMarkets] = useState({
        level: 1,
        maxMarkets: selectorConfiguration.maxMarkets,
    });
    const {company} = useUser();
    useEffect(() => {
        //TODO : THIS CODE WILL BE MOVED TO storiesContext FILE
        if (selectedMarkets.length === 0 || selectedMarkets.some(item=>item.disabledMarketNode)) {
            updatePanelStatus(selectorIndex, selectorStates.pending);
        } else {
            updatePanelStatus(selectorIndex, selectorStates.completed);
        }
        setDataSpecificationValues({
            ...dataSpecificationValues,
            runConfig: { ...dataSpecificationValues.runConfig, markets: selectedMarkets.filter(item=>!item.disabledMarketNode),
                parentCompany:selectedMarkets.some(item=>item.isParentCompany)?selectedMarkets.map(item=>item.name):{},
             },
        });
    }, [selectedMarkets]);
    async function handleMarketChange(e: ByzzerChangeEvent<RunConfigMarket[] | MarketPickerRunConfigMarket[]>) {
        let tempSelectedMarkets = e.value.map((marketObject) => {
            const { selectionType, comparativeMarketKey } = marketObject.isParentCompany? marketObject:getCachedMarketNodeByName(marketObject.name)!;
            if (!marketObject.selectionType && selectionType) {
                marketObject = { ...marketObject, selectionType: selectionType };
            }
            if (
                !marketObject.comparativeMarketKey &&
                comparativeMarketKey &&
                marketObject.remainingMarketRunConfig?.key === comparativeMarketKey
            ) {
                marketObject = { ...marketObject, comparativeMarketKey: comparativeMarketKey };
            }
            return marketObject;
        });
        if (tempSelectedMarkets.length > 1 && selectorConfiguration.isSelectionTypeExclusive) {
            if (
                tempSelectedMarkets[0].marketGroup !== tempSelectedMarkets[tempSelectedMarkets.length - 1].marketGroup
            ) {
                tempSelectedMarkets = [tempSelectedMarkets[tempSelectedMarkets.length - 1]];
            }
        }

        setSelectedMarkets(tempSelectedMarkets);
    }

    const updateMarketAlias = (e: any) => {
        setDataSpecificationValues({
            ...dataSpecificationValues,
            runConfig: { ...dataSpecificationValues.runConfig, marketAlias: e.target.value },
        });
    };
    useEffect(() => {
        if (dataSpecificationValues.runConfig.markets.length)
            setRequiredMarketGroup(dataSpecificationValues.runConfig.markets[0].marketGroup);
        else setRequiredMarketGroup('');
    }, [dataSpecificationValues.runConfig.markets]);
    useEffect(() => {
        
        if (selectorConfiguration.datatype !== 'cps' && dataSpecificationValues.runConfig.categories.length !== categories.length) {
            setCategories([...dataSpecificationValues.runConfig.categories]);
            setSelectedMarkets([]);
        }
    }, [dataSpecificationValues.runConfig.categories]);

    return (
        <>
            <div>
                {selectorConfiguration.showSelectedMarketCount && selectedMaxMarkets.maxMarkets > 1 && (
                    <b className="selected-markets-count">{`Number of market(s) selected: ${dataSpecificationValues.runConfig.markets.length}`}</b>
                )}
                <ByzzerMarketSearch
                    label={ <SelectorLabelInfo 
                               selectorCode={'focusMarkets'} 
                               sku={dataSpecificationValues.sku} 
                               max={selectorConfiguration.maxMarkets}
                            />}
                    name={'markets'}
                    categories={categories}
                    productSku={dataSpecificationValues.sku}
                    requireRemainingMarket={selectorConfiguration.requireRemainingMarket}
                    showRemainingMarkets={selectorConfiguration.showRemainingMarkets ?? false}
                    value={selectedMarkets}
                    maxSelections={selectorConfiguration.maxMarkets}
                    onChange={handleMarketChange}
                />
                <MarketPicker
                    key={`${categories?.sort().join()}`}
                    value={selectedMarkets}
                    onChange={handleMarketChange}
                    maxSelections={selectedMaxMarkets.maxMarkets}
                    categories={categories}
                    requireRemainingMarket={selectorConfiguration.requireRemainingMarket}
                    name={'markets'}
                    productSku={dataSpecificationValues.sku}
                    requiredMarketGroup={requiredMarketGroup}
                    includePseudoRemainingMarkets={selectorConfiguration.includePseudoRemainingMarkets}
                    runType={'subscription'}
                    showRemainingMarkets={selectorConfiguration.showRemainingMarkets}
                    displayMarketGroup={selectorConfiguration.datatype}
                    purchasedMarketKeys={company?.purchasedMarketKeys}
                    enableParentMarket={dataSpecificationValues.runConfig.datatype === 'cps'}
                />
            </div>
            <div>
                {selectorConfiguration.includeMarketAlias && (
                    <>
                        <div className={`product_selector__focus-brand-item-story`}>                           
                             <SelectorLabelInfo 
                               selectorCode={'marketAlias'} 
                               sku={dataSpecificationValues.sku} 
                               isLabelBold={true}
                            />   
                        </div>
                        <div className={`product_selector__subset-option-text-two`}>
                            Rename the market to something that will be relevant to you. For example, the retailer
                            Albertsons Companies Total Company could be renamed to "Albertsons"
                        </div>
                        <div className={`product_selector__submit-btn-container__alias-container`}>
                            <ByzzerInput
                                label={undefined}
                                button={undefined}
                                onBlur
                                locked={undefined}
                                required
                                sublabel
                                disabled={undefined}
                                onIconClick
                                onButtonClick
                                style={{ width: '20em' }}
                                placeholder={'Product Name'}
                                onChange={updateMarketAlias}
                                className={`product_selector__alias-input`}
                                name={'marketAlias'}
                                value={dataSpecificationValues.runConfig.marketAlias}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
